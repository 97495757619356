<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-08-24 19:28:26
 * @LastEditors: g05047
 * @LastEditTime: 2023-02-14 22:00:23
-->
<template>
  <div id="app">
    <navigationBar
      v-if="isShow"
      :isShow="isShow"
      @onChangeActive="onChangeActive"
      @handleClose="handleClose"
    ></navigationBar>
  </div>
</template>
<script>
import navigationBar from "./drawModule.vue"
import imageUrlApi from "@/components/component/js/imgurlApi.js"
export default {
  components: {
    navigationBar,
  },
  data() {
    return { isShow: true }
  },
  methods: {
    handleClose(path) {
      this.isShow = false
    },
    onChangeActive(record) {
      if (record == "settingLanguage") {
        this.$router.push("/language/language-setting")
        this.isShow = false
      }
      if (record == "shopRegister") {
        this.$JumpShops(imageUrlApi.shopRegisterWebUrl)
      }
      if (record == "shopLogin") {
        this.$JumpShops(imageUrlApi.shopLoginWebUrl)
      }
      if (record == "service") {
        this.$router.push("/serviceIndex")
        this.isShow = false
      }

      if (record == "downloadAndroidApp") {
        window.location.href = imageUrlApi.downloadAndroidAppUrl
      }

      if (record == "downloadIosApp") {
        window.location.href = imageUrlApi.downloadIosAppUrl
      }
    },
    navigationWays() {
      this.isShow = true
    },
  },
}
</script>
<style>
.navigation_bar {
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: 400px;
  right: 20px;
  z-index: 3000;
  background-color: rgba(255, 193, 193, 0.5);
  border-radius: 50%;
  box-shadow: 10px 0px 15px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation_bar img {
  width: 62px;
  height: 62px;
}
</style>
