// 中文

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: '版本升级，请确认？ ',//版本升级，请确认？
    loadingTitle: '正在下载...', // 正在下载...
    systemUpgrade: '系统升级', // 系统升级
    upgradeImmediately: '确认', // 确认
}

// 登录
let loginLanguage = {
    navTitle: '登录', // 登录
    title: '登录',
    continue: '登录以继续',
    forget: '忘记密码？',//客户服务
    btnTitle: '登录', //
    placeholderAccount: '请输入电话号码',
    placeholderPassword: '请输入密码', //
    leaveFor: '去注册',
    haveTitle: '没有账号？ ',
    errorTips: '帐号或密码错误',
    loginShopTitle: '商户登录入口'

}

// 忘记密码
let forgetPassword = {
    navTitle: '忘记密码', // 忘记密码
    oldPass: '请输入您的手机号码', // 输入手机号
    verificationCode: '短信验证码',
    newPass: '请输入新密码',
    Obtain: '发送',//获取
    btnTitle: '确认',
    notSaved: '手机号码不正确',
}

// 注册
let registerLanguage = {
    navTitle: '注册', // 注册
    title: '注册',
    continue: '注册成为会员',
    forget: '忘记密码？ ',
    btnTitle: '注册', // 注册
    placeholderAccount: '请输入手机号码',
    placeholderVerification: '邀请码',
    placeholderPassword: '请输入密码', //
    Obtain: '发送',//
    haveTitle: '已经有账号了？',
    leaveFor: '去登录',
    notSaved: '手机号码不正确',
}

// 首页
let homeLanguage = {
    navTitle: '热门推荐', // 家
    placeholder: '输入产品名称进行搜索',// 请输入内容
    tabsAll: "所有", // 推荐
    headerBgTitle: '0资金入驻跨境店铺，去注册',
}

// 提示文本
let messageLanguage = {
    loadingTitle: '加载中', // 正在加载
    finishedText: "无", //无
    loadingText: '加载中', // 正在加载
}

// 产品列表
let orderPageLangage = {
    navTitle: "产品列表", // 订单列表
    placeholder: "输入要搜索的产品名称", //搜索条件
    goodsTitle: '商品', // 商品
    shopTitle: '店铺',//点店铺
    goTitle: 'GO',//转到
};

//  产品详情
let orderDetailLangage = {
    navTitle: "产品详情", // 订单列表
    returnPolicy: '退货政策',//退货政策
    slectTitle: '选择',//返回策略
    slectContent: '选择产品规格',//退货政策
    Shipping: '地址',//收货地址
    ShippingContent: '運送至目的地',//收货地址
    freight: '运费',
    freightContent: '免运费', // 免运费
    Product: '产品',//产品
    commentsDiscuss: '评论',//评论
    Details: '详细信息',//详细信息
    Recommend: '推荐',//推荐
    ReturnPolicyTitle: '退货政策',
    policyBtn: '确认',
    specQuantity: '数量',
    specBtnbuyNow: '加入购物车',
    specBtnShoppingCar: '立即购买',
    attrDefault: '默认',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: '店铺详情'
}

// 评论详情
let commentOnLanguage = {
    navTitle: '更多评论'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '多语言设置',
    affirm: '确认',
}
// 页脚
let footerLanguage = {
    home: '首页',
    order: '购物车',
    Workbench: '个人中心',
}
// 导航设置
let popupLanguage = {
    settingLanguage: '多语言设置',
    shopRegister: '店铺注册',
    shopLogin: '店铺登录',
    service:'客服',
    downloadAndroidApp:'下载Android应用程序',
    downloadIosApp:'下载ios应用程序',
}

// 客服
let serviceLanguage = {
    navTitle: '客服',
    customerService: '专属客服',
    helloTitle: '你好',
    welcomeTitle: ' 我是您的 <， 您可以通过以下方式联系我',
    iconInformation: '请选择沟通软件进行咨询',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
