const hostConfigObj = {

    // api请求
    baseUrl: 'https://api.byzbfl.com/mall_v200',
    // 图片域名
    url: 'https://image.byzbfl.com/',
    // 安卓app下载地址
    downloadAndroidAppUrl: 'https://source.byzbfl.com/download/TikTokm.Shop.apk',
    // 苹果app下载地址
    downloadIosAppUrl:'https://source.byzbfl.com/download/TikTokm.Shop.mobileconfig',
    // 店铺域名
    storeHost: 'https://seller.tkonline.shop/',
}
export { hostConfigObj }