import imgurlApi from './imgurlApi'
let $environment = 'web'
// let $environment = 'android'
// let $environment = 'ios'

/**
 * 打开地址
 *
 * @param params
 * @returns {boolean}
 */
function $JumpShops(params = '') {
    let languageType = window.localStorage.getItem('languageType') || ''

    let code = window.localStorage.getItem('code') == null ? '' : window.localStorage.getItem('code')

    let jumpUrl = imgurlApi.storeHost + '?language=' + languageType + '&' + 'code=' + code + params + '';

    if (!params.includes('?')) {
        jumpUrl = jumpUrl + '?';
    }

    $openUrl(jumpUrl + '&code=' + code + '&language=' + languageType);

    return true;
}

/**
 *
 * 跳转地址
 *
 * @param url
 * @returns {boolean}
 */
function $openUrl(url)
{
    if ($environment === 'android') {
        plus.runtime.openURL(url)
        return true;
    }

    window.location.href = url

    return true;
}

/**
 * 是否展示
 *
 * @returns {boolean}
 */
function $showContent()
{
    return $environment === 'web';
}

export default {
    $environment,
    $JumpShops,
    $openUrl,
    $showContent
}