// 德语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Versions-Upgrade, bitte bestätigen? ',// Versionsaktualisierung, bitte bestätigen?
    loadingTitle: 'Wird heruntergeladen...', // Wird heruntergeladen...
    systemUpgrade: 'System-Upgrade', // System-Upgrade
    upgradeImmediately: 'Bestätigen', // Bestätigen
}

// 登录
let loginLanguage = {
    navTitle: 'Anmeldung', // Anmeldung
    title: 'Anmelden',
    continue: 'Anmelden um fortzufahren',
    forget: 'Passwort vergessen?', // Kundenservice
    btnTitle: 'Anmelden', //
    placeholderAccount: 'Bitte geben Sie die Telefonnummer ein',
    placeholderPassword: 'Bitte geben Sie Ihr Passwort ein', //
    leaveFor: 'Zur Registrierung gehen',
    haveTitle: 'Kein Konto? ',
    errorTips: 'Konto- oder Passwortfehler',
    loginShopTitle: 'Händler-Login-Eintrag'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'Passwort vergessen', // Passwort vergessen
    oldPass: 'Bitte geben Sie Ihre Handynummer ein', // Geben Sie die Handynummer ein
    verificationCode: 'SMS-Bestätigungscode',
    newPass: 'Bitte geben Sie ein neues Passwort ein',
    Obtain: 'Senden',// Erhalten
    btnTitle: 'Bestätigen',
    notSaved: 'Die Telefonnummer ist falsch',
}

// 注册
let registerLanguage = {
    navTitle: 'registrieren', // registrieren
    title: 'Anmelden',
    continue: 'Als Mitglied registrieren',
    forget: 'Passwort vergessen? ',
    btnTitle: 'registrieren', // registrieren
    placeholderAccount: 'Bitte geben Sie die Telefonnummer ein',
    placeholderVerification: 'Einladungscode',
    placeholderPassword: 'Bitte geben Sie Ihr Passwort ein', //
    Obtain: 'Senden', //
    haveTitle: 'Sie haben bereits ein Konto?',
    leaveFor: 'Zur Anmeldung gehen',
    notSaved: 'Die Telefonnummer ist falsch',
}

// 首页
let homeLanguage = {
    navTitle: 'Beliebte Empfehlung', // Home
    placeholder: 'Geben Sie den zu suchenden Produktnamen ein',// Bitte geben Sie den Inhalt ein
    tabsAll: "Alle", // Empfohlen
    headerBgTitle: '0 im grenzüberschreitenden Shop abgerechnete Gelder, zur Registrierung gehen',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Laden', // Laden
    finishedText: "keine", //keine
    loadingText: 'Laden', // Laden
}

// 产品列表
let orderPageLangage = {
    navTitle: "Produktliste", // Bestellliste
    placeholder: 'Geben Sie den zu suchenden Produktnamen ein', //Suchkriterien
    goodsTitle: 'Waren', // Waren
    shopTitle: 'shop',// Punktladen
    goTitle: 'GO',//Gehe zu
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Produktdetails", // Bestellliste
    returnPolicy: 'Rückgaberecht',//Rückgaberecht
    slectTitle: 'auswählen',//Rückgaberecht
    slectContent: 'Produktspezifikation auswählen',//Rückgaberecht
    Shipping: 'Adresse',//Lieferadresse
    ShippingContent: 'zum Zielort versenden',//Lieferadresse
    freight: 'Fracht',
    freightContent: 'kostenloser Versand', // kostenloser Versand
    Product: 'Produkt',//Produkt
    commentsDiscuss: 'kommentare',//kommentare
    Details: 'Einzelheiten',//Einzelheiten
    Recommend: 'Empfehlen',//Empfehlen
    ReturnPolicyTitle: 'Rückgaberecht',
    policyBtn: 'Bestätigen',
    specQuantity: 'Menge',
    specBtnbuyNow: 'Zum Warenkorb hinzufügen',
    specBtnShoppingCar: 'Jetzt kaufen',
    attrDefault: 'Standard',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Details speichern'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'mehr Kommentare'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Mehrsprachige Einstellungen',
    affirm: 'bestätigen',
}
// 页脚
let footerLanguage = {
    home: 'Hause',
    order: 'AUTO',
    Workbench: 'PERSÖNLICH',
}
// 导航设置
let popupLanguage = {
    settingLanguage: 'Mehrsprachige Einstellungen',
    shopRegister: 'Store-Registrierung',
    shopLogin: 'Store-Login',
    service: 'Kundendienst',
    downloadAndroidApp: 'Android-App herunterladen',
    downloadIosApp: 'ios-App herunterladen',
}
// 客服
let serviceLanguage = {
    navTitle: 'Kundenservice',
    customerService: 'Exklusiver Kundenservice',
    helloTitle: 'Hallo',
    welcomeTitle: 'Ich bin Ihr <, Sie können mich auf folgende Weise kontaktieren',
    IconInformation: 'Bitte Kommunikationssoftware zur Beratung auswählen',
    whatsApp: 'whatsapp',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}


/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Empfohlen',
    tabes2: 'shop',
    searchPlaceholder: 'Bitte eingeben, um zu suchen',
    listScroll1: 'Bestellungen',
    listScroll2: 'Aufladecenter',
    listScroll3: 'Folge dem Shop',
    listScroll4: 'Neuigkeiten zum Kundenservice',
    listScroll5: 'Nachricht',
    listScroll6: 'Favoriten',
    listScroll7: 'Adresse',
    listScroll8: 'Kartencoupon',
    listScroll9: 'Anmelden',
    recommendCard1: 'Tägliche Empfehlung',
    recommendCard2: 'Super-Preis-Kauf',
    recommendCard3: 'Empfohlen',
    recommendCard4: 'Niedriger Preisanstieg',
}
//  新增首页语言
homeLanguage = Object.assign({...homeLanguage, ...newHome6month7})

let newOrderDetail6month7 = {
    commentContent: 'Das System ist standardmäßig auf Lob eingestellt! ',
    discussTags1: 'alle',
    discussTags2: '1 Stern',
    discussTags3: '2 Sterne',
    discussTags4: '3 Sterne',
    discussTags5: '4 Sterne',
    discussTags6: '5 Sterne',
    discussTags7: 'Es gibt Medien', //Mediendatei
    cheaper: 'billiger',
    SalesVolume: 'Verkaufsvolumen',
    pieces: 'Stücke',
    leaveFor: 'GO',
    resumption: 'bald',
    hint1: 'Gesamtumsatz',
    hint2: 'Gesamtbestellung',
    hint3: 'Gesamtprodukt',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({...orderDetailLangage, ...newOrderDetail6month7})

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'Das System ist standardmäßig auf Lob eingestellt! ',
    tabsTitle1: 'Alle',
    tabsTitle2: '1 Stern',
    tabsTitle3: '2 Sterne',
    tabsTitle4: '3 Sterne',
    tabsTitle5: '4 Sterne',
    tabsTitle6: '5 Sterne',
    tabsTitle7: 'Medien haben',
}
commentOnLanguage = Object.assign({...commentOnLanguage, ...newComment6month7})
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7: 'Um mehr zu sehen, melden Sie sich bitte zuerst an'
}
messageLanguage = Object.assign({...newMessage6month7, ...messageLanguage})
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
