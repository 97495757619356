import axios from 'axios'
import Vue from 'vue'
import router from 'vue-router'
import routers from '../router/index'
import baseUrl from '../components/component/js/imgurlApi'
import languageType from "@/components/component/js/language";
Vue.use(router)
import {
  Toast
} from "vant";
let messageLoading
Vue.nextTick(() => {
  messageLoading = languageType(localStorage.getItem("languageType")).messageLanguage.loadingTitle
})

const ServerRequestUrl = baseUrl.baseUrl
// 创建axios实例
const service = axios.create({
  baseURL: ServerRequestUrl
})
// request拦截器==>对请求参数做处理
service.interceptors.request.use(config => {
  Toast.loading({
    forbidClick: true,
    duration: 2500,
    message: messageLoading + '...'
  })
  config.headers.Authorization = window.localStorage.getItem('authentication');
  config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]
  return config
}, error => { // 请求错误处理
  Promise.reject(error)
})
// respone拦截器==>对响应做处理
service.interceptors.response.use(
  response => { // 成功请求到数据
    // 这里根据后端提供的数据进行对应的处理
    if (response.data.code === 200) {
      Toast.clear()
    } else if (response.data.code == 1002) {
      localStorage.clear()
      routers.replace({
        path: '/login',

      })
    } else {
      Toast.fail({
        message: response.data.message,
        forbidClick: true,
      })
    }
    return response.data
  },
  error => { // 响应错误处理
    Toast.clear()
    let text = JSON.parse(JSON.stringify(error)).name === 'Error' ? 'network anomaly' : 'network anomaly'
    return Promise.reject(error)
  }
)
export default service