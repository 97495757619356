// 西班牙语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Actualización de versión, ¿confirmar? ', // Actualización de versión, por favor confirme?
    loadingTitle: 'Descargando...', // Descargando...
    systemUpgrade: 'actualización del sistema', // actualización del sistema
    upgradeImmediately: 'Confirmar', // Confirmar
}

// 登录
let loginLanguage = {
    navTitle: 'iniciar sesión', // iniciar sesión
    title: 'Iniciar sesión',
    continue: 'Iniciar sesión para continuar',
    forget: '¿Olvidó su contraseña?', // atención al cliente
    btnTitle: 'Iniciar sesión', //
    placeholderAccount: 'Ingrese el número de teléfono',
    placeholderPassword: 'Por favor ingrese su contraseña', //
    leaveFor: 'Ir a registrarse',
    haveTitle: '¿No tienes cuenta? ',
    errorTips: 'Error de cuenta o contraseña',
    loginShopTitle:'Entrada de inicio de sesión del comerciante'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'contraseña olvidada', // contraseña olvidada
    oldPass: 'Ingrese su número de teléfono móvil', // ingrese el número de teléfono móvil
    verificationCode: 'Código de verificación de SMS',
    newPass: 'Por favor ingrese una nueva contraseña',
    Obtain: 'Enviar', // Obtener
    btnTitle: 'Confirmar',
    notSaved: 'El número de teléfono es incorrecto',
}

// 注册
let registerLanguage = {
    navTitle: 'registrarse', // registrarse
    title: 'Registrarse',
    continue: 'Registrarse como miembro',
    forget: '¿Olvidaste tu contraseña? ',
    btnTitle: 'registrarse', // registrarse
    placeholderAccount: 'Ingrese el número de teléfono',
    placeholderVerification: 'Código de invitación',
    placeholderPassword: 'Por favor ingrese su contraseña', //
    Obtain: 'Enviar', //
    haveTitle: '¿Ya tienes una cuenta?',
    leaveFor: 'Ir a iniciar sesión',
    notSaved: 'El número de teléfono es incorrecto',
}

// 首页
let homeLanguage = {
    navTitle: 'Recomendación popular', // Inicio
    placeholder: 'Ingrese el nombre del producto para buscar', // Ingrese el contenido
    tabsAll: "Todos", // Recomendado
    headerBgTitle: '0 fondos liquidados en tienda transfronteriza, vaya a registrarse',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Cargando', // Cargando
    finishedText: "ninguno", //ninguno
    loadingText: 'cargando', // cargando
}

// 产品列表
let orderPageLangage = {
    navTitle: "Lista de productos", // Lista de pedidos
    placeholder: "Ingrese el nombre del producto para buscar", // Criterios de búsqueda
    goodsTitle: 'bienes', // bienes
    shopTitle: 'tienda', // punto de tienda
    goTitle: 'IR', // Ir a
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Detalles del producto", // Lista de pedidos
    returnPolicy: 'Política de devoluciones',//Política de devoluciones
    slectTitle: 'seleccionar',//política de devoluciones
    slectContent: 'Seleccionar especificación de producto',//Política de devoluciones
    Shipping: 'dirección',//dirección de envío
    ShippingContent: 'enviar a destino',//dirección de envío
    freight: 'Flete',
    freightContent: 'envío gratuito', // envío gratuito
    Product: 'Producto',//Producto
    commentsDiscuss: 'comentarios',//comentarios
    Details: 'detalles',//detalles
    Recommend: 'Recomendar',//Recomendar
    ReturnPolicyTitle: 'Política de devoluciones',
    policyBtn: 'Confirmar',
    specQuantity: 'Cantidad',
    specBtnbuyNow: 'Agregar al carrito',
    specBtnShoppingCar: 'Comprar ahora',
    attrDefault: 'predeterminado',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Detalles de la tienda'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'Más comentarios'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Configuración multilingüe',
    affirm: 'confirmar',
}
// 页脚
let footerLanguage = {
    home: 'HOGAR',
    order: 'COCHE',
    Workbench: 'PERSONAL',
}
// 导航设置
let popupLanguage={
    settingLanguage:'Configuración multilingüe',
    shopRegister:'Registro de tienda',
    shopLogin:'inicio de sesión en la tienda',
    service:'servicio al cliente',
    downloadAndroidApp:'descargar aplicación de Android',
    downloadIosApp:'descargar aplicación ios',
}
// 客服
let serviceLanguage = {
    navTitle: 'servicio al cliente',
    customerService: 'Servicio al cliente exclusivo',
    helloTitle: 'Hola',
    welcomeTitle: 'Soy tu <, puedes contactarme a través de las siguientes formas',
    IconInformation: 'Seleccione el software de comunicación para consultar',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}



/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Recomendado',
    tabes2: 'tienda',
    searchPlaceholder: "Por favor ingrese para buscar",
    listScroll1: 'Pedidos',
    listScroll2: 'Centro de recarga',
    listScroll3: 'Sigue la tienda',
    listScroll4: 'Noticias de servicio al cliente',
    listScroll5: 'Mensaje',
    listScroll6: 'Favoritos',
    listScroll7: 'dirección',
    listScroll8: 'Cupón de tarjeta',
    listScroll9: 'Iniciar sesión',
    recommendCard1: 'Recomendación diaria',
    recommendCard2: 'Compra de gran valor',
    recommendCard3: 'Destacado',
    recommendCard4: 'Subida de precio bajo',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: '¡El sistema por defecto es alabar! ',
    discussTags1 : 'todos',
    discussTags2: '1 estrella',
    discussTags3: '2 estrellas',
    discussTags4: '3 estrellas',
    discussTags5: '4 estrellas',
    discussTags6: '5 estrellas',
    discussTags7: 'Hay medios', //archivo multimedia
    cheaper: 'más barato',
    SalesVolume: 'Volumen de ventas',
    pieces: 'piezas',
    leaveFor: 'IR',
    resumption: 'próximamente',
    hint1: 'ventas totales',
    hint2: 'Pedido total',
    hint3: 'producto total',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: '¡El sistema por defecto es alabar! ',
    tabsTitle1: 'Todos',
    tabsTitle2: '1 estrella',
    tabsTitle3: '2 estrellas',
    tabsTitle4: '3 estrellas',
    tabsTitle5: '4 estrellas',
    tabsTitle6: '5 estrellas',
    tabsTitle7: 'Tener medios',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'Para ver más, inicie sesión primero'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
