<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-09-19 22:42:53
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-19 23:10:57
-->
<template>
<!--  :style="{ height: '48%' }"-->
  <div class="drawModule_warper">
    <van-popup
      class="drawPopup"
      v-model="show"
      round
      position="top"
      @close="handleClose"
    >
      <div class="drawModule_content">
        <div
          v-for="(item, index) in navigationBarList"
          :key="index"
          :class="['drawModule_item']"
        >
          <div :class="['drawName']" @click="onChangeActive(item)">
            <p :class="[activeIndex == item.key ? 'activeIndex' : '']">
              {{ item.label }}
            </p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>


<script>
import languageType from "@/components/component/js/language";

export default {
  props: {
    isShow: { type: Boolean, default: () => {} },
  },
  data() {
    return {
      languageList: "",
      show: false,
      navigationBarList: [],
      activeIndex: null,
    };
  },
  mounted() {
    this.show = this.isShow;
    let language = localStorage.getItem("languageType");
    this.languageList = languageType(language).popupLanguage;
    this.navigationBarList = [
      { key: "settingLanguage", label: this.languageList.settingLanguage },
      { key: "shopRegister", label: this.languageList.shopRegister },
      { key: "shopLogin", label: this.languageList.shopLogin },
      { key: "service", label: this.languageList.service },
    ];
    if (this.$globalCommon.$showContent()) {
      this.navigationBarList.push(
          { key: "downloadAndroidApp", label: this.languageList.downloadAndroidApp },
          { key: "downloadIosApp", label: this.languageList.downloadIosApp }
      );
    }
  },
  methods: {
    onChangeActive(record) {
      this.activeIndex = record.key;
      this.$emit("onChangeActive", this.activeIndex);
    },
    handleClose() {
      this.show = false;
      this.$emit("handleClose", this.show);
    },
  },
};
</script>


<style lang='scss' scoped>
.drawModule_warper {
  width: 100%;
  .drawPopup {
    width: 100%;
    padding: 40px;
    .drawModule_content {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .drawModule_item {
        width: 100%;
        display: flex;
        align-items: center;
        height: 94px;
        .drawName {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-family: Helvetica Neue LT Pro;
          font-weight: normal;
          color: #6a6a6a;
        }
        .activeIndex {
          font-size: 36px;
          font-weight: 600;
          height: 60%;
          color: rgb(255, 193, 193);
          border-bottom: 6px solid rgb(255, 193, 193);
        }
      }
    }
  }
}
</style>
