// 意大利语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Aggiornamento versione, conferma? ',// Aggiornamento della versione, conferma?
    loadingTitle: 'Download in corso...', // Download in corso...
    systemUpgrade: 'aggiornamento del sistema', // aggiornamento del sistema
    upgradeImmediately: 'Conferma', // Conferma
}

// 登录
let loginLanguage = {
    navTitle: 'login', // login
    title: 'Accedi',
    continue: 'Accedi per continuare',
    forget: 'Hai dimenticato la password?', // servizio clienti
    btnTitle: 'Accedi', //
    placeholderAccount: 'Inserisci il numero di telefono',
    placeholderPassword: 'Inserisci la tua password', //
    leaveFor: 'Vai alla registrazione',
    haveTitle: 'Nessun account? ',
    errorTips: 'Errore account o password',
    loginShopTitle: 'Inserimento login commerciante'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'password dimenticata', // password dimenticata
    oldPass: 'Inserisci il tuo numero di cellulare', // inserisci il numero di cellulare
    verificationCode: 'Codice di verifica SMS',
    newPass: 'Inserisci una nuova password',
    Obtain: 'Invia',// Ottieni
    btnTitle: 'Conferma',
    notSaved: 'Il numero di telefono non è corretto',
}

// 注册
let registerLanguage = {
    navTitle: 'registrati', // registrati
    title: 'Iscriviti',
    continue: 'Registrati come membro',
    forget: 'Hai dimenticato la password? ',
    btnTitle: 'registro', // registro
    placeholderAccount: 'Inserisci il numero di telefono',
    placeholderVerification: 'Codice di invito',
    placeholderPassword: 'Inserisci la tua password', //
    Obtain: 'Invia', //
    haveTitle: 'Hai già un account?',
    leaveFor: 'Vai al login',
    notSaved: 'Il numero di telefono non è corretto',
}

// 首页
let homeLanguage = {
    navTitle: 'Raccomandazione popolare', // Home
    placeholder: 'Inserisci il nome del prodotto da cercare',// Inserisci il contenuto
    tabsAll: "Tutto", // Consigliato
    headerBgTitle: '0 fondi regolati in un negozio transfrontaliero, vai alla registrazione',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Caricamento in corso', // Caricamento in corso
    finishedText: "nessuno", //nessuno
    loadingText: 'caricamento', // caricamento
}

// 产品列表
let orderPageLangage = {
    navTitle: "Elenco prodotti", // Elenco ordini
    placeholder: "Inserisci il nome del prodotto da cercare", //Criteri di ricerca
    goodsTitle: 'merci', // merci
    shopTitle: 'negozio',// punto negozio
    goTitle: 'VAI',//Vai a
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Dettagli prodotto", // Elenco ordini
    returnPolicy: 'Return Policy',//Return Policy
    slectTitle: 'select',//politica di restituzione
    slectContent: 'Seleziona le specifiche del prodotto',//Politica di restituzione
    Shipping: 'indirizzo',//indirizzo di spedizione
    ShippingContent: 'spedire a destinazione',//indirizzo di spedizione
    freight: 'Merci',
    freightContent: 'spedizione gratuita', // spedizione gratuita
    Product: 'Prodotto',//Prodotto
    commentsDiscuss: 'comments',//comments
    Details: 'dettagli',//dettagli
    Recommend: 'Consiglia',//Consiglia
    ReturnPolicyTitle: 'Politica di restituzione',
    policyBtn: 'Conferma',
    specQuantity: 'Quantità',
    specBtnbuyNow: 'Aggiungi al carrello',
    specBtnShoppingCar: 'Acquista ora',
    attrDefault: 'predefinito',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Dettagli negozio'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'più commenti'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Impostazioni multilingue',
    affirm: 'conferma',
}
// 页脚
let footerLanguage = {
    home: 'casa',
    order: 'CAR',
    Workbench: 'PERSONALE',
}
// 导航设置
let popupLanguage = {
    settingLanguage: 'Impostazioni multilingue',
    shopRegister: 'Registrazione negozio',
    shopLogin: 'login negozio',
    service: 'servizio clienti',
    downloadAndroidApp: 'scarica app Android',
    downloadIosApp: 'scarica app ios',
}
// 客服
let serviceLanguage = {
    navTitle: 'servizio clienti',
    customerService: 'Servizio clienti esclusivo',
    helloTitle: 'Ciao',
    welcomeTitle: 'Sono il tuo <, puoi contattarmi nei seguenti modi',
    IconInformation: 'Selezionare il software di comunicazione per la consultazione',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}


/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Consigliato',
    tabes2: 'negozio',
    searchPlaceholder: "Inserisci per cercare",
    listScroll1: 'Ordini',
    listScroll2: 'Centro di ricarica',
    listScroll3: 'Segui il negozio',
    listScroll4: 'Notizie servizio clienti',
    listScroll5: 'Messaggio',
    listScroll6: 'Preferiti',
    listScroll7: 'indirizzo',
    listScroll8: 'Coupon Carta',
    listScroll9: 'Accedi',
    recommendCard1: 'Raccomandazione giornaliera',
    recommendCard2: 'Acquisto super conveniente',
    recommendCard3: 'In primo piano',
    recommendCard4: 'Picco di prezzo basso',
}
//  新增首页语言
homeLanguage = Object.assign({...homeLanguage, ...newHome6month7})

let newOrderDetail6month7 = {
    commentContent: 'Il sistema di default loda! ',
    discussTags1: 'tutto',
    discussTags2: '1 stella',
    discussTags3: '2 stelle',
    discussTags4: '3 stelle',
    discussTags5: '4 stelle',
    discussTags6: '5 stelle',
    discussTags7: 'C\'è un supporto', //file multimediale
    cheaper: 'più economico',
    SalesVolume: 'Volume delle vendite',
    pieces: 'pezzi',
    leaveFor: 'VAI',
    resumption: 'prossimamente',
    hint1: 'vendite totali',
    hint2: 'Ordine totale',
    hint3: 'prodotto totale',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({...orderDetailLangage, ...newOrderDetail6month7})

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'Il sistema di default loda! ',
    tabsTitle1: 'Tutto',
    tabsTitle2: '1 stella',
    tabsTitle3: '2 stelle',
    tabsTitle4: '3 stelle',
    tabsTitle5: '4 stelle',
    tabsTitle6: '5 stelle',
    tabsTitle7: 'Avere contenuti multimediali',
}
commentOnLanguage = Object.assign({...commentOnLanguage, ...newComment6month7})
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7: 'Per vedere di più, per favore accedi prima'
}
messageLanguage = Object.assign({...newMessage6month7, ...messageLanguage})
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
