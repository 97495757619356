// 韩语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: '버전 업그레이드, 확인하시겠습니까? ',// 버전 업그레이드, 확인해주세요?
    loadingTitle: '다운로드 중...', // 다운로드 중...
    systemUpgrade: '시스템 업그레이드', // 시스템 업그레이드
    upgradeImmediately: '확인', // 확인
}

// 登录
let loginLanguage = {
    navTitle: '로그인', // 로그인
    title: '로그인',
    continue: '계속하려면 로그인하세요',
    forget: '비밀번호를 잊으셨나요?', // 고객 서비스
    btnTitle: '로그인', //
    placeholderAccount: '전화번호를 입력하세요',
    placeholderPassword: '비밀번호를 입력하세요', //
    leaveFor: '등록하러 가기',
    haveTitle: '계정이 없나요? ',
    errorTips: '계정 또는 비밀번호 오류',
    loginShopTitle:'판매자 로그인 항목'


}

// 忘记密码
let forgetPassword = {
    navTitle: '비밀번호 분실', // 비밀번호 분실
    oldPass: '휴대전화 번호를 입력하세요', // 휴대전화 번호를 입력하세요.
    verificationCode: 'SMS 인증 코드',
    newPass: '새 비밀번호를 입력하세요',
    Obtain: '보내기',// 획득
    btnTitle: '확인',
    notSaved: '전화번호가 올바르지 않습니다.',
}

// 注册
let registerLanguage = {
    navTitle: '등록', // 등록
    title: '가입하기',
    continue: '회원가입',
    forget: '비밀번호를 잊으셨나요? ',
    btnTitle: '등록', // 등록
    placeholderAccount: '전화번호를 입력하세요',
    placeholderVerification: '초대 코드',
    placeholderPassword: '비밀번호를 입력하세요', //
    Obtain: '보내기', //
    haveTitle: '이미 계정이 있으신가요?',
    leaveFor: '로그인으로 이동',
    notSaved: '전화번호가 올바르지 않습니다.',
}

// 首页
let homeLanguage = {
    navTitle: '인기 추천', // 홈
    placeholder: '검색할 상품명을 입력하세요.',// 내용을 입력해주세요.
    tabsAll: "전체", // 권장
    headerBgTitle: '크로스보더 샵에 결제된 자금이 0개, 등록하러 가기',
}

// 提示文本
let messageLanguage = {
    loadingTitle: '로드 중', // 로드 중
    finishedText: "없음", //없음
    loadingText: '로드 중', // 로드 중
}

// 产品列表
let orderPageLangage = {
    navTitle: "제품 목록", // 주문 목록
    placeholder: "검색할 제품 이름 입력", //검색 기준
    goodsTitle: '상품', // 상품
    shopTitle: '샵',// 포인트샵
    goTitle: 'GO',//바로가기
};

//  产品详情
let orderDetailLangage = {
    navTitle: "제품 세부 정보", // 주문 목록
    returnPolicy: '반품 정책',//반품 정책
    slectTitle: '선택',//반환 정책
    slectContent: '제품 사양 선택',//반품 정책
    Shipping: '주소', //배송지 주소
    ShippingContent: '목적지로 배송',//배송 주소
    freight: '화물',
    freightContent: '무료 배송', // 무료 배송
    Product: '제품',//제품
    commentsDiscuss: '댓글',//댓글
    Details: '세부정보',//세부정보
    Recommend: '추천합니다',//추천합니다
    ReturnPolicyTitle: '반품 정책',
    policyBtn: '확인',
    specQuantity: '수량',
    specBtnbuyNow: '장바구니에 담기',
    specBtnShoppingCar: '지금 구매하기',
    attrDefault: '기본값',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: '점포 상세'
}

// 评论详情
let commentOnLanguage = {
    navTitle: '더 많은 의견'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '다국어 설정',
    affirm: '확인하다',
}
// 页脚
let footerLanguage = {
    home: '집',
    order: '자동차',
    Workbench: '개인',
}
// 导航设置
let popupLanguage={
    settingLanguage:'다국어 설정',
    shopRegister:'매장 등록',
    shopLogin:'스토어 로그인',
    service:'고객 서비스',
    downloadAndroidApp:'안드로이드 앱 다운로드',
    downloadIosApp:'iOS 앱 다운로드',
}
 // 客服
let serviceLanguage = {
    navTitle: '고객 서비스',
    customerService: '독점 고객 서비스',
    helloTitle: '안녕하세요',
    welcomeTitle: '나는 당신의 <입니다. 다음 방법을 통해 저에게 연락하실 수 있습니다.',
    IconInformation: '상담할 통신 소프트웨어를 선택하세요',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}



/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: '추천',
    tabes2: '가게',
    searchPlaceholder: "검색하려면 입력하세요",
    listScroll1: '주문',
    listScroll2: '충전 센터',
    listScroll3: '매장 팔로우',
    listScroll4: '고객 서비스 뉴스',
    listScroll5: '메시지',
    listScroll6: '즐겨찾기',
    listScroll7: '주소',
    listScroll8: '카드 쿠폰',
    listScroll9: '로그인',
    recommendCard1: '일일 추천',
    recommendCard2: '초특가 구매',
    recommendCard3: '추천',
    recommendCard4: '낮은 가격 급등',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: '시스템은 기본적으로 찬양으로 설정되어 있습니다! ',
    discussTags1: '모두',
    discussTags2: '별점 1개',
    discussTags3: '별 2개',
    discussTags4: '별 3개',
    discussTags5: '별점 4개',
    discussTags6: '별점 5개',
    discussTags7: '미디어가 있습니다', //미디어 파일
    cheaper: '저렴한',
    SalesVolume: '판매량',
    pieces: '조각',
    leaveFor: '이동',
    resumption: '곧',
    hint1: '총 매출',
    hint2: '총 주문량',
    hint3: '전체 제품',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: '시스템은 기본적으로 찬양으로 설정되어 있습니다! ',
    tabsTitle1: '모두',
    tabsTitle2: '별점 1개',
    tabsTitle3: '별 2개',
    tabsTitle4: '별 3개',
    tabsTitle5: '별점 4개',
    tabsTitle6: '별점 5개',
    tabsTitle7: '미디어 있음',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'더 보려면 먼저 로그인하십시오'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
