// 土耳其语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Sürüm yükseltme, lütfen onaylayın? ',// Sürüm yükseltme, lütfen onaylayın?
    loadingTitle: 'İndiriliyor...', // İndiriliyor...
    systemUpgrade: 'sistem yükseltme', // sistem yükseltme
    upgradeImmediately: 'Onayla', // Onayla
}

// 登录
let loginLanguage = {
    navTitle: 'giriş', // giriş
    title: 'Giriş',
    continue: 'Devam etmek için giriş yapın',
    forget: 'Şifrenizi mi unuttunuz?', // müşteri hizmetleri
    btnTitle: 'Giriş', //
    placeholderAccount: 'Lütfen telefon numarasını girin',
    placeholderPassword: 'Lütfen şifrenizi girin', //
    leaveFor: 'Kayıt olmaya git',
    haveTitle: 'Hesap yok mu? ',
    errorTips: 'Hesap veya şifre hatası',
    loginShopTitle:'Tüccar giriş girişi'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'şifremi unuttum', // şifremi unuttum
    oldPass: 'Lütfen cep telefonu numaranızı girin', // cep telefonu numarasını girin
    verificationCode: 'SMS doğrulama kodu',
    newPass: 'Lütfen yeni bir şifre girin',
    Obtain: 'Gönder',// Al
    btnTitle: 'Onayla',
    notSaved: 'Telefon numarası yanlış',
}

// 注册
let registerLanguage = {
    navTitle: 'kayıt', // kayıt
    title: 'Kaydol',
    continue: 'Üye olarak kayıt ol',
    forget: 'Şifrenizi mi unuttunuz? ',
    btnTitle: 'kayıt', // kayıt
    placeholderAccount: 'Lütfen telefon numarasını girin',
    placeholderVerification: 'Davetiye Kodu',
    placeholderPassword: 'Lütfen şifrenizi girin', //
    Obtain: 'Gönder', //
    haveTitle: 'Zaten bir hesabınız var mı?',
    leaveFor: 'Giriş yapmaya git',
    notSaved: 'Telefon numarası yanlış',
}

// 首页
let homeLanguage = {
    navTitle: 'Popüler öneri', // Ana Sayfa
    placeholder: 'Aramak için ürün adını girin',// Lütfen içeriği girin
    tabsAll: "Tümü", // Önerilen
    headerBgTitle: 'Sınır ötesi mağazada yer alan 0 fon, kayıt olmaya gidin',

}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Yükleniyor', // Yükleniyor
    finishedText: "yok", //yok
    loadingText: 'yükleniyor', // yükleniyor
}

// 产品列表
let orderPageLangage = {
    navTitle: "Ürün Listesi", // Sipariş Listesi
    placeholder: "Aramak için ürün adını girin", //Arama kriterleri
    goodsTitle: 'mal', // mal
    shopTitle: 'mağaza',// nokta mağazası
    goTitle: 'GİT',//Git
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Ürün Ayrıntıları", // Sipariş Listesi
    returnPolicy: 'İade Politikası',//İade Politikası
    slectTitle: 'seç',//dönüş politikası
    slectContent: 'Ürün özelliklerini seçin',//İade politikası
    Shipping: 'adres',//gönderim adresi
    ShippingContent: 'varış noktasına gemi',//gönderim adresi
    freight: 'Navlun',
    freightContent: 'ücretsiz kargo', // ücretsiz kargo
    Product: 'Ürün',//Ürün
    commentsDiscuss: 'yorumlar',//yorumlar
    Details: 'detaylar',//detaylar
    Recommend: 'Tavsiye Edin',//Tavsiye Edin
    ReturnPolicyTitle: 'İade Politikası',
    policyBtn: 'Onayla',
    specQuantity: 'Miktar',
    specBtnbuyNow: 'Sepete ekle',
    specBtnShoppingCar: 'Şimdi Satın Alın',
    attrDefault: 'varsayılan',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Mağaza ayrıntıları'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'daha fazla yorum'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Çok dilli ayarlar',
    affirm: 'onaylamak',
}
// 页脚
let footerLanguage = {
    home: 'EV',
    order: 'ARABA',
    Workbench: 'BEN',
}
// 导航设置
let popupLanguage={
    settingLanguage:'Çok dilli ayarlar',
    shopRegister:'Mağaza kaydı',
    shopLogin:'mağaza girişi',
    service:'müşteri hizmetleri',
    downloadAndroidApp:'android uygulamasını indir',
    downloadIosApp:'ios uygulamasını indir',
}
 // 客服
let serviceLanguage = {
    navTitle: 'müşteri hizmetleri',
    customerService: 'Özel müşteri hizmeti',
    helloTitle: 'Merhaba',
    welcomeTitle: 'Ben sizin <, aşağıdaki yollarla benimle iletişime geçebilirsiniz',
    IconInformation: 'Lütfen danışma için iletişim yazılımını seçin',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}



/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Önerilen',
    tabes2: 'alışveriş',
    searchPlaceholder: "Lütfen aramak için giriniz",
    listScroll1: 'Siparişler',
    listScroll2: 'Şarj Merkezi',
    listScroll3: 'Mağazayı takip et',
    listScroll4: 'Müşteri hizmetleri haberleri',
    listScroll5: 'Mesaj',
    listScroll6: 'Favoriler',
    listScroll7: 'adres',
    listScroll8: 'Kart Kuponu',
    listScroll9: 'Giriş yap',
    recommendCard1: 'Günlük tavsiye',
    recommendCard2: 'Süper değerli satın alma',
    recommendCard3: 'Öne Çıkanlar',
    recommendCard4: 'Düşük fiyat artışı',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: 'Sistem varsayılan olarak övüyor! ',
    discussTags1: 'hepsi',
    discussTags2: '1 yıldız',
    discussTags3: '2 yıldız',
    discussTags4: '3 yıldız',
    discussTags5: '4 yıldız',
    discussTags6: '5 yıldız',
    discussTags7: 'Medya var', //medya dosyası
    cheaper: 'daha ucuz',
    SalesVolume: 'Satış Hacmi',
    pieces: 'parçalar',
    leaveFor: 'GİT',
    resumption: 'çok yakında',
    hint1: 'toplam satış',
    hint2: 'Toplam sipariş',
    hint3: 'toplam ürün',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'Sistem varsayılan olarak övüyor! ',
    tabsTitle1: 'Tümü',
    tabsTitle2: '1 yıldız',
    tabsTitle3: '2 yıldız',
    tabsTitle4: '3 Yıldız',
    tabsTitle5: '4 yıldız',
    tabsTitle6: '5 Yıldız',
    tabsTitle7: 'Medya var',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'Daha fazlasını görmek için lütfen önce giriş yapın'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
