// 法语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Mise à jour de la version, veuillez confirmer? ',// Mise à jour de la version, veuillez confirmer?
    loadingTitle: 'Téléchargement...', // Téléchargement...
    systemUpgrade: 'mise à niveau du système', // mise à niveau du système
    upgradeImmediately: 'Confirmer', // Confirmer
}

// 登录
let loginLanguage = {
    navTitle: 'connexion', // connexion
    title: 'Connexion',
    continue: 'Connexion pour continuer',
    forget: 'Mot de passe oublié?', // service client
    btnTitle: 'Connexion', //
    placeholderAccount: 'Veuillez entrer le numéro de téléphone',
    placeholderPassword: 'Veuillez entrer votre mot de passe', //
    leaveFor: 'Aller au registre',
    haveTitle: 'Pas de compte? ',
    errorTips: 'Erreur de compte ou de mot de passe',
    loginShopTitle:'Entrée de connexion marchand'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'mot de passe oublié', // mot de passe oublié
    oldPass: 'Veuillez entrer votre numéro de téléphone mobile', // entrez le numéro de téléphone mobile
    verificationCode: 'Code de vérification par SMS',
    newPass: 'Veuillez saisir un nouveau mot de passe',
    Obtain : 'Envoyer',// Obtenir
    btnTitle: 'Confirmer',
    notSaved: 'Le numéro de téléphone est incorrect',
}

// 注册
let registerLanguage = {
    navTitle: 's\'inscrire', // s'inscrire
    title: 'Inscrivez-vous',
    continue: 'S\'inscrire en tant que membre',
    forget : 'Mot de passe oublié ? ',
    btnTitle: 's\'inscrire', // s'inscrire
    placeholderAccount: 'Veuillez entrer le numéro de téléphone',
    placeholderVerification: 'Code d\'invitation',
    placeholderPassword: 'Veuillez entrer votre mot de passe', //
    Obtain: 'Envoyer', //
    haveTitle: 'Vous avez déjà un compte?',
    leaveFor: 'Aller à la connexion',
    notSaved: 'Le numéro de téléphone est incorrect',
}

// 首页
let homeLanguage = {
    navTitle: 'Recommandation populaire', // Accueil
    placeholder: 'Entrez le nom du produit à rechercher',// Veuillez saisir le contenu
    tabsAll: "Tous", // Recommandé
    headerBgTitle: '0 fonds réglés dans un magasin transfrontalier, allez vous inscrire',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Chargement', // Chargement
    finishedText : "aucun", //aucun
    loadingText: 'chargement', // chargement
}

// 产品列表
let orderPageLangage = {
    navTitle: "Liste de produits", // Liste de commandes
    placeholder: "Entrez le nom du produit à rechercher", //Critères de recherche
    goodsTitle: 'marchandises', // marchandises
    shopTitle: 'boutique',// pointer la boutique
    goTitle: 'GO',//Aller à
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Détails du produit", // Liste de commandes
    returnPolicy: 'Politique de retour',//Politique de retour
    slectTitle: 'select',//politique de retour
    slectContent: 'Sélectionner la spécification du produit',//Politique de retour
    Shipping: 'adresse',//adresse d'expédition
    ShippingContent: 'expédier à destination',//adresse de livraison
    freight : 'Fret',
    freightContent: 'livraison gratuite', // livraison gratuite
    Product: 'Produit',//Produit
    commentsDiscuss: 'commentaires',//commentaires
    Details : 'détails',//détails
    Recommend : 'Recommander',//Recommander
    ReturnPolicyTitle: 'Politique de retour',
    policyBtn: 'Confirmer',
    specQuantity: 'Quantité',
    specBtnbuyNow: 'Ajouter au panier',
    specBtnShoppingCar: "Acheter maintenant",
    attrDefault: 'par défaut',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Détails de la boutique'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'plus de commentaires'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Paramètres multilingues',
    affirm: 'confirmer',
}
// 页脚
let footerLanguage = {
    home: 'Maison',
    order : 'CAR',
    Workbench : 'PERSONNEL',
}
// 导航设置
let popupLanguage={
    settingLanguage:'Paramètres multilingues',
    shopRegister:'Enregistrement de magasin',
    shopLogin:'connexion au magasin',
    service:'service client',
    downloadAndroidApp:'télécharger l\'application Android',
    downloadIosApp:'télécharger l\'application ios',
}
 // 客服
let serviceLanguage = {
    navTitle: 'service client',
    customerService: 'Service client exclusif',
    helloTitle: 'Bonjour',
    welcomeTitle: 'Je suis votre <, vous pouvez me contacter par les moyens suivants',
    IconInformation: 'Veuillez sélectionner un logiciel de communication pour consultation',
    whatsApp: 'whatsApp',
    telegram: 'telegram',
    line: 'line',
    kakao: 'kakao'
}



/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Recommandé',
    tabes2: 'boutique',
    searchPlaceholder: "Veuillez entrer pour rechercher",
    listScroll1: 'Commandes',
    listScroll2: 'Centre de recharge',
    listScroll3: 'Suivre le magasin',
    listScroll4: 'Actualités du service client',
    listScroll5: 'Message',
    listScroll6: 'Favoris',
    listScroll7: 'adresse',
    listScroll8: 'Coupon de carte',
    listScroll9: 'Connexion',
    recommendCard1: 'Recommandation quotidienne',
    recommendCard2: 'Achat à très bon prix',
    recommendCard3: 'Mis en exergue',
    recommendCard4: 'Pic de prix bas',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: 'Le système par défaut est en louange! ',
    discussTags1: 'tous',
    discussTags2: '1 étoile',
    discussTags3: '2 étoiles',
    discussTags4: '3 étoiles',
    discussTags5: '4 étoiles',
    discussTags6: '5 étoiles',
    discussTags7: 'Il y a un média', //fichier média
    cheaper: 'moins cher',
    SalesVolume: 'Volume des ventes',
    pieces: 'morceaux',
    leaveFor: 'GO',
    resumption: 'arrive bientôt',
    hint1: 'ventes totales',
    hint2: 'Total de la commande',
    hint3: 'produit total',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'Le système par défaut est en louange! ',
    tabsTitle1: 'Tous',
    tabsTitle2: '1 étoile',
    tabsTitle3: '2 étoiles',
    tabsTitle4: '3 étoiles',
    tabsTitle5: '4 étoiles',
    tabsTitle6: '5 étoiles',
    tabsTitle7: 'Avoir des médias',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'Pour en voir plus, veuillez d\'abord vous connecter'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
