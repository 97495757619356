// 芬兰语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Version päivitys, vahvista? ',// Version päivitys, vahvistatko?
    loadingTitle: 'Ladataan...', // Ladataan...
    systemUpgrade: 'järjestelmän päivitys', // järjestelmän päivitys
    upgradeImmediately: 'Vahvista', // Vahvista
}

// 登录
let loginLanguage = {
    navTitle: 'kirjautuminen', // kirjaudu sisään
    title: "Kirjaudu sisään",
    continue: 'Jatka kirjautumalla',
    forget: 'Unohditko salasanasi?', // asiakaspalvelu
    btnTitle: 'Kirjaudu', //
    placeholderAccount: 'Anna puhelinnumero',
    placeholderPassword: 'Anna salasanasi', //
    leaveFor: 'Siirry rekisteröitymiseen',
    haveTitle: 'Ei tiliä? ',
    errorTips: "Tili- tai salasanavirhe",
    loginShopTitle:'Kauppiaan kirjautumistunnus'
}

// 忘记密码
let forgetPassword = {
    navTitle: 'unohdin salasanan', // unohdin salasanan
    oldPass: 'Anna matkapuhelinnumerosi', // kirjoita matkapuhelinnumero
    verificationCode: "SMS-vahvistuskoodi",
    newPass: "Anna uusi salasana",
    Obtain: 'Lähetä',// Hanki
    btnTitle: 'Vahvista',
    notSaved: 'Puhelinnumero on väärä',
}

// 注册
let registerLanguage = {
    navTitle: 'register', // rekisteröidy
    title: "Rekisteröidy",
    continue: "Rekisteröidy jäseneksi",
    forget: 'Unohditko salasanasi? ',
    btnTitle: 'register', // rekisteröidy
    placeholderAccount: 'Anna puhelinnumero',
    placeholderVerification: "Kutsukoodi",
    placeholderPassword: 'Anna salasanasi', //
    Obtain: "Lähetä", //
    haveTitle: "Onko sinulla jo tili?",
    leaveFor: 'Siirry kirjautumiseen',
    notSaved: 'Puhelinnumero on väärä',
}

// 首页
let homeLanguage = {
    navTitle: 'Suosittu suositus', // Etusivu
    placeholder: 'Anna haettavan tuotteen nimi',// Anna sisältö
    tabsAll: "Kaikki", // Suositeltava
    headerBgTitle: '0 rahaa maksettu rajat ylittävään kauppaan, mene rekisteröitymään',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Ladataan', // Ladataan
    finishedText: "ei mitään", //ei mitään
    loadingText: 'lataus', // lataus
}

// 产品列表
let orderPageLangage = {
    navTitle: "Tuoteluettelo", // Tilauslista
    placeholder: "Syötä tuotteen nimi hakuun", //Hakuehdot
    goodsTitle: 'tavarat', // tavarat
    shopTitle: 'kauppa',// pistekauppa
    goTitle: 'GO',//Siirry
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Tuotetiedot", // Tilauslista
    returnPolicy: 'Palautuskäytäntö',//Palautuskäytäntö
    slectTitle: 'select',//palautuskäytäntö
    slectContent: 'Valitse tuotetiedot',//Palautuskäytäntö
    Shipping: 'osoite',//toimitusosoite
    ShippingContent: "lähettää määränpäähän",//toimitusosoite
    freight: "Freight",
    freightContent: 'ilmainen toimitus', // ilmainen toimitus
    Product: 'Tuote',//Tuote
    commentsDiscuss: 'kommentit',//kommentit
    Details: 'yksityiskohdat',//details
    Recommend: 'Suosittele',//Suosittele
    ReturnPolicyTitle: 'Palautuskäytäntö',
    policyBtn: 'Confirm',
    specQuantity: 'Quantity',
    specBtnbuyNow: 'Lisää ostoskoriin',
    specBtnShoppingCar: "Osta nyt",
    attrDefault: 'oletus',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Myymälän tiedot'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'lisää kommentteja'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Monikieliset asetukset',
    affirm: 'vahvista',
}
// 页脚
let footerLanguage = {
    home: 'HOME',
    order: 'CAR',
    Workbench: 'PERSONAL',
}
// 导航设置
let popupLanguage={
    settingLanguage:'Monikieliset asetukset',
    shopRegister: "Kaupparekisteröinti",
    shopLogin:'store login',
    service: "asiakaspalvelu",
    downloadAndroidApp: "lataa Android-sovellus",
    downloadIosApp:'lataa ios-sovellus',
}
 // 客服
let serviceLanguage = {
    navTitle: 'asiakaspalvelu',
    customerService: "Yksinomaista asiakaspalvelua",
    helloTitle: "Hei",
    welcomeTitle: "Olen sinun <, voit ottaa minuun yhteyttä seuraavilla tavoilla",
    IconInformation: 'Valitse viestintäohjelmisto konsultaatiota varten',
    whatsApp: 'whatsApp',
    telegram: 'sähkö',
    line: 'line',
    kakao: 'kakao'
}



/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Suositeltu',
    tabes2: 'kauppa',
    searchPlaceholder: "Hae syöttämällä",
    listScroll1: 'Tilaukset',
    listScroll2: 'Recharge Center',
    listScroll3: 'Seuraa kauppaa',
    listScroll4: 'Asiakaspalveluuutiset',
    listScroll5: 'Viesti',
    listScroll6: 'Suosikit',
    listScroll7: 'osoite',
    listScroll8: 'Korttikuponki',
    listScroll9: "Kirjaudu sisään",
    recommendCard1: 'Päivittäinen suositus',
    recommendCard2: 'Super-arvoostos',
    recommendCard3: "Suositeltu",
    recommendCard4: "Low price spike",
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: 'Järjestelmän oletuksena on kehuminen! ',
    discussTags1: 'kaikki',
    discussTags2: '1 tähti',
    discussTags3: '2 tähteä',
    discussTags4: '3 tähteä',
    discussTags5: '4 tähteä',
    discussTags6: '5 tähteä',
    discussTags7: "Mediaa on", //mediatiedosto
    cheaper: "halvempi",
    SalesVolume: 'Myyntivolyymi',
    pieces: 'palaset',
    leaveFor: 'GO',
    resumption: "tulossa pian",
    hint1: 'kokonaismyynti',
    hint2: "Kokonaistilaus",
    hint3: "koko tuote",

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'Järjestelmän oletuksena on kehuminen! ',
    tabsTitle1: "Kaikki",
    tabsTitle2: '1 tähti',
    tabsTitle3: '2 tähteä',
    tabsTitle4: "3 tähteä",
    tabsTitle5: '4 tähteä',
    tabsTitle6: "5 tähteä",
    tabsTitle7: 'Käytä mediaa',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'Jos haluat nähdä lisää, kirjaudu ensin sisään'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
