// 繁体中文

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: '版本升級，請確認？ ',//版本升級，請確認？
    loadingTitle: '正在下載...', // 正在下載...
    systemUpgrade: '系統升級', // 系統升級
    upgradeImmediately: '確認', // 確認
}

// 登录
let loginLanguage = {
    navTitle: '登錄', // 登錄
    title: '登錄',
    continue: '登錄以繼續',
    forget: '忘記密碼？ ',//客戶服務
    btnTitle: '登錄', //
    placeholderAccount: '請輸入手機號碼',
    placeholderPassword: '請輸入密碼', //
    leaveFor: '去註冊',
    haveTitle: '沒有賬號？ ',
    errorTips: '帳號或密碼錯誤',
    loginShopTitle: '商戶登錄入口'

}

// 忘记密码
let forgetPassword = {
    navTitle: '忘記密碼', // 忘記密碼
    oldPass: '請輸入您的手機號', // 輸入手機號
    verificationCode: '短信驗證碼',
    newPass: '請輸入新密碼',
    Obtain: '發送',//獲取
    btnTitle: '確認',
    notSaved: '手機號碼不正確',
}

// 注册
let registerLanguage = {
    navTitle: '註冊', // 註冊
    title: '註冊',
    continue: '註冊成為會員',
    forget: '忘記密碼？ ',
    btnTitle: '註冊', // 註冊
    placeholderAccount: '請輸入手機號碼',
    placeholderVerification: '邀請碼',
    placeholderPassword: '請輸入密碼', //
    Obtain: '發送',//
    haveTitle: '已經有賬號了？ ',
    leaveFor: '去登錄',
    notSaved: '手機號碼不正確',
}

// 首页
let homeLanguage = {
    navTitle: '熱門推薦', // 家
    placeholder: '輸入產品名稱進行搜索',// 請輸入內容
    tabsAll: "所有", // 推薦
    headerBgTitle: '0資金入駐跨境店鋪，去註冊',
}

// 提示文本
let messageLanguage = {
    loadingTitle: '加載中', // 正在加載
    finishedText: "無", //無
    loadingText: '加載中', // 正在加載
}

// 产品列表
let orderPageLangage = {
    navTitle: "產品列表", // 訂單列表
    placeholder: "輸入要搜索的產品名稱", //搜索條件
    goodsTitle: '商品', // 商品
    shopTitle: '店鋪',//點店鋪
    goTitle: 'GO',//轉到
};

//  产品详情
let orderDetailLangage = {
    navTitle: "產品詳情", // 訂單列表
    returnPolicy: '退貨政策',//退貨政策
    slectTitle: '選擇',//返回策略
    slectContent: '選擇產品規格',//退貨政策
    Shipping: '地址',//收貨地址
    ShippingContent: '運送至目的地',//收貨地址
    freight: '運費',
    freightContent: '免運費', // 免運費
    Product: '產品',//產品
    commentsDiscuss: '評論',//評論
    Details: '詳細信息',//詳細信息
    Recommend: '推薦',//推薦
    ReturnPolicyTitle: '退貨政策',
    policyBtn: '確認',
    specQuantity: '數量',
    specBtnbuyNow: '加入購物車',
    specBtnShoppingCar: '立即購買',
    attrDefault: '默認',

    discussTags1:'ALL',
    discussTags2:'Praise',
    discussTags3:'Bad comment ',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: '店鋪詳情'
}

// 评论详情
let commentOnLanguage = {
    navTitle: '更多評論'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '多語言設置',
    affirm: '確認',
}
// 页脚
let footerLanguage = {
    home: '首頁',
    order: '購物車',
    Workbench: '個人中心',
}
// 导航设置
let popupLanguage = {
    settingLanguage: '多語言設置',
    shopRegister: '店鋪註冊',
    shopLogin: '店铺登錄',
    service:'客服',
    downloadAndroidApp:'下載Android應用程序',
    downloadIosApp:'下載ios應用程序',
}
// 客服
let serviceLanguage = {
    navTitle: '客服',
    customerService: '專屬客服',
    helloTitle: '你好',
    welcomeTitle: ' 我是您的<，您可以通過以下管道聯系我',
    iconInformation: '請選擇溝通軟件進行諮詢',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}



/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: '推薦',
    tabes2: '店鋪',
    searchPlaceholder: "請輸入進行搜索",
    listScroll1: '訂單',
    listScroll2: '充值中心',
    listScroll3: '關注店鋪',
    listScroll4: '客服消息',
    listScroll5: '留言',
    listScroll6: '收藏',
    listScroll7: '地址',
    listScroll8: '卡券',
    listScroll9: '簽到',
    recommendCard1: '每日推薦',
    recommendCard2: '超值購',
    recommendCard3: '精選',
    recommendCard4: '低價秒殺',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent:'系統默認好評！ ',
    discussTags1: '所有',
    discussTags2: '1星',
    discussTags3: '2星',
    discussTags4: '3星 ',
    discussTags5: '4星',
    discussTags6: '5星',
    discussTags7: '有媒體 ', //媒體文件
    cheaper: '更便宜',
    SalesVolume: '銷售數量',
    pieces: '件',
    leaveFor: 'GO',
    resumption: '即將恢復',
    hint1: '總銷量',
    hint2: '總訂單',
    hint3: '總產品',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent:'系統默認好評！ ',
    tabsTitle1: '所有',
    tabsTitle2: '1星',
    tabsTitle3: '2星',
    tabsTitle4: '3星',
    tabsTitle5: '4星',
    tabsTitle6: '5星',
    tabsTitle7: '有媒體',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'查看更多，請先登錄'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
