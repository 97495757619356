// 荷兰语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Versie-upgrade, gelieve te bevestigen? ',// Versie-upgrade, gelieve te bevestigen?
    loadingTitle: 'Downloaden...', // Downloaden...
    systemUpgrade: 'systeem upgrade', // systeem upgrade
    upgradeImmediately: 'Bevestigen', // Bevestigen
}

// 登录
let loginLanguage = {
    navTitle: 'inloggen', // inloggen
    title: 'Inloggen',
    continue: 'Log in om verder te gaan',
    forget: 'Wachtwoord vergeten?', // klantenservice
    btnTitle: 'Inloggen', //
    placeholderAccount: 'Voer het telefoonnummer in',
    placeholderPassword: 'Voer uw wachtwoord in', //
    leaveFor: 'Ga naar inschrijven',
    haveTitle: 'Geen account? ',
    errorTips: 'Account- of wachtwoordfout',
    loginShopTitle: 'Inloggegevens verkoper'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'wachtwoord vergeten', // wachtwoord vergeten
    oldPass: 'Voer uw mobiele telefoonnummer in', // voer het mobiele telefoonnummer in
    verificationCode: 'SMS-verificatiecode',
    newPass: 'Voer een nieuw wachtwoord in',
    Obtain: 'Verzenden',// Ophalen
    btnTitle: 'Bevestigen',
    notSaved: 'Het telefoonnummer is onjuist',
}

// 注册
let registerLanguage = {
    navTitle: 'registreren', // registreren
    title: 'Aanmelden',
    continue: 'Registreren als lid',
    forget: 'Wachtwoord vergeten? ',
    btnTitle: 'registreren', // registreren
    placeholderAccount: 'Voer het telefoonnummer in',
    placeholderVerification: 'Uitnodigingscode',
    placeholderPassword: 'Voer uw wachtwoord in', //
    Obtain: 'Verzenden', //
    haveTitle: 'Heeft u al een account?',
    leaveFor: 'Ga naar inloggen',
    notSaved: 'Het telefoonnummer is onjuist',
}

// 首页
let homeLanguage = {
    navTitle: 'Populaire aanbeveling', // Home
    placeholder: 'Voer de productnaam in om te zoeken',// Voer de inhoud in
    tabsAll: "Alles", // Aanbevolen
    headerBgTitle: '0 fondsen verrekend in grensoverschrijdende winkel, ga naar register',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Bezig met laden', // Bezig met laden
    finishedText: "geen", // geen
    loadingText: 'laden', // laden
}

// 产品列表
let orderPageLangage = {
    navTitle: "Productlijst", // Bestellijst
    placeholder: "Voer productnaam in om te zoeken", //Zoekcriteria
    goodsTitle: 'goederen', // goederen
    shopTitle: 'winkel',// puntwinkel
    goTitle: 'GA',//Ga naar
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Productdetails", // Bestellijst
    returnPolicy: 'Retourbeleid',//Retourbeleid
    slectTitle: 'selecteer',//retourbeleid
    slectContent: 'Selecteer productspecificatie',//Retourbeleid
    Shipping: 'adres',//verzendadres
    ShippingContent: 'verzenden naar bestemming',//verzendadres
    freight: 'Vracht',
    freightContent: 'gratis verzending', // gratis verzending
    Product: 'Product',//Product
    commentsDiscuss: 'comments',//comments
    Details: 'details',//details
    Recommend: 'Aanbevelen',//Aanbevelen
    ReturnPolicyTitle: 'Retourbeleid',
    policyBtn: 'Bevestigen',
    specQuantity: 'Aantal',
    specBtnbuyNow: 'Toevoegen aan winkelwagen',
    specBtnShoppingCar: 'Nu kopen',
    attrDefault: 'standaard',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Bewaar gegevens'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'meer opmerkingen'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Meertalige instellingen',
    affirm: 'bevestigen',
}
// 页脚
let footerLanguage = {
    home: 'huis',
    order: 'AUTO',
    Workbench: 'PERSOONLIJK',
}
// 导航设置
let popupLanguage = {
    settingLanguage: 'Meertalige instellingen',
    shopRegister: 'Winkelregistratie',
    shopLogin: 'winkel login',
    service: 'klantenservice',
    downloadAndroidApp: 'Android-app downloaden',
    downloadIosApp: 'ios-app downloaden',
}
// 客服
let serviceLanguage = {
    navTitle: 'klantenservice',
    customerService: 'Exclusieve klantenservice',
    helloTitle: 'Hallo',
    welcomeTitle: 'Ik ben uw <, u kunt op de volgende manieren contact met mij opnemen',
    IconInformation: 'Selecteer communicatiesoftware voor raadpleging',
    whatsApp: 'Whatsapp',
    telegram: 'telegram',
    line: 'lijn',
    kakao: 'kakao'
}



/**    
 * 2023年6月7日21:02:19新版商城
  */

let newHome6month7 = {
    tabes1: 'Aanbevolen',
    tabes2: 'winkel',
    searchPlaceholder: "Voer in om te zoeken",
    listScroll1: 'Bestellingen',
    listScroll2: 'Oplaadcentrum',
    listScroll3: 'Volg de winkel',
    listScroll4: 'Klantenservice nieuws',
    listScroll5: 'Bericht',
    listScroll6: 'Favorieten',
    listScroll7: 'adres',
    listScroll8: 'Kaartcoupon',
    listScroll9: 'Aanmelden',
    recommendCard1: 'Dagelijkse aanbeveling',
    recommendCard2: 'Super voordelige aankoop',
    recommendCard3: 'Aanbevolen',
    recommendCard4: 'Lage prijspiek',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: 'Het systeem is standaard ingesteld op prijzen! ',
    discussTags1: 'alle',
    discussTags2: '1 ster',
    discussTags3: '2 sterren',
    discussTags4: '3 sterren',
    discussTags5: '4 sterren',
    discussTags6: '5 sterren',
    discussTags7: 'Er zijn media', //mediabestand
    cheaper: 'goedkoper',
    SalesVolume: 'Verkoopvolume',
    pieces: 'stukken',
    leaveFor: 'GO',
    resumption: 'binnenkort beschikbaar',
    hint1: 'totale verkoop',
    hint2: 'Totale bestelling',
    hint3: 'totaalproduct',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'Het systeem is standaard ingesteld op prijzen! ',
    tabsTitle1: 'Alle',
    tabsTitle2: '1 ster',
    tabsTitle3: '2 sterren',
    tabsTitle4: '3 sterren',
    tabsTitle5: '4 sterren',
    tabsTitle6: '5 sterren',
    tabsTitle7: 'Heb media',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'Log eerst in om meer te zien'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
