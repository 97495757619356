// 阿拉伯语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'ترقية الإصدار , يرجى التأكيد؟ ', // ترقية الإصدار , يرجى التأكيد؟
    loadingTitle: "جارٍ التنزيل ...", // جارٍ التنزيل ...
    systemUpgrade: "ترقية النظام", // ترقية النظام
    upgradeImmediately: "تأكيد", // تأكيد
}

// 登录
let loginLanguage = {
    navTitle: "تسجيل الدخول", // تسجيل الدخول
    title: "تسجيل الدخول",
    continue: "تسجيل الدخول للمتابعة",
    forget: "هل نسيت كلمة المرور؟", // خدمة العملاء
    btnTitle: "تسجيل الدخول", //
    placeholderAccount: "الرجاء إدخال رقم الهاتف",
    placeholderPassword: "الرجاء إدخال كلمة المرور", //
    leaveFor: "اذهب للتسجيل",
    haveTitle: " ليس لديك حساب؟ ",
    errorTips: "خطأ في الحساب أو كلمة المرور",
    loginShopTitle: "إدخال تسجيل دخول التاجر"

}

// 忘记密码
let forgetPassword = {
    navTitle: "نسيت كلمة المرور", // نسيت كلمة المرور
    oldPass: "الرجاء إدخال رقم هاتفك المحمول", // أدخل رقم الهاتف المحمول
    verificationCode: "رمز التحقق SMS",
    newPass: "الرجاء إدخال كلمة مرور جديدة",
    Obtain: "إرسال", // احصل
    btnTitle: "تأكيد",
    notSaved: "رقم الهاتف غير صحيح",
}

// 注册
let registerLanguage = {
    navTitle: "تسجيل", // تسجيل
    title: "تسجيل",
    continue: "تسجيل كعضو",
    forget: " هل نسيت كلمة المرور؟ ",
    btnTitle: "تسجيل", // تسجيل
    placeholderAccount: "الرجاء إدخال رقم الهاتف",
    placeholderVerification: "كود الدعوة",
    placeholderPassword: "الرجاء إدخال كلمة المرور", //
    Obtain: "إرسال", //
    haveTitle: "هل لديك حساب بالفعل؟",
    leaveFor: "اذهب إلى تسجيل الدخول",
    notSaved: "رقم الهاتف غير صحيح",
}

// 首页
let homeLanguage = {
    navTitle: "توصية شائعة", // الصفحة الرئيسية
    placeholder: "أدخل اسم المنتج للبحث", // الرجاء إدخال المحتوى
    tabsAll: "الكل", // مستحسن
    headerBgTitle: "0 أموال تمت تسويتها في متجر عبر الحدود , انتقل للتسجيل",
}

// 提示文本
let messageLanguage = {
    loadingTitle: "تحميل", // تحميل
    finishedText: "لا شيء", // لا شيء
    loadingText: "تحميل", // تحميل
}

// 产品列表
let orderPageLangage = {
    navTitle: "قائمة المنتجات", // قائمة الطلبات
    placeholder: "أدخل اسم المنتج للبحث", // معايير البحث
    goodsTitle: "البضائع", // البضائع
    shopTitle: "متجر", // نقطة متجر
    goTitle: "GO", // Go to
};

//  产品详情
let orderDetailLangage = {
    navTitle: "تفاصيل المنتج", // قائمة الطلبات
    returnPolicy: "سياسة الإرجاع", // سياسة الإرجاع
    slectTitle: "حدد", // سياسة الإرجاع
    slectContent: "حدد مواصفات المنتج", // سياسة الإرجاع
    Shipping: "العنوان", // عنوان الشحن
    ShippingContent: "شحن إلى الوجهة", // عنوان الشحن
    freight: "الشحن",
    freightContent: "شحن مجاني", // شحن مجاني
    Product: "المنتج", // المنتج
    commentsDiscuss: "التعليقات", // التعليقات
    Details: "تفاصيل", // تفاصيل
    Recommend: "يوصي", // يوصي
    ReturnPolicyTitle: " سياسة الإرجاع",
    policyBtn: "تأكيد",
    specQuantity: "الكمية",
    specBtnbuyNow: "أضف إلى عربة التسوق",
    specBtnShoppingCar: "اشتر الآن",
    attrDefault: "افتراضي",
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'تفاصيل المتجر'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'المزيد من التعليقات'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'إعدادات متعددة اللغات',
    affirm: 'تؤكد',
}
// 页脚
let footerLanguage = {
    home: 'الصفحة الرئيسية',
    order: 'جمل',
    Workbench: 'شخصي',
}
// 导航设置
let popupLanguage = {
    settingLanguage: "إعدادات متعددة اللغات",
    shopRegister: "تسجيل المتجر",
    shopLogin: "تسجيل الدخول إلى المتجر",
    service: "خدمة العملاء",
    downloadAndroidApp: " تنزيل تطبيق android ",
    downloadIosApp: "تنزيل تطبيق ios",
}
// 客服
let serviceLanguage = {
    navTitle: "خدمة العملاء",
    customerService: "خدمة عملاء حصرية",
    helloTitle: "مرحبًا",
    welcomeTitle: "أنا لك <, يمكنك الاتصال بي بالطرق التالية",
    IconInformation: "الرجاء تحديد برنامج الاتصال للتشاور",
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}


/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: "مستحسن",
    tabes2: "متجر",
    searchPlaceholder: "الرجاء إدخال البحث",
    listScroll1: "الطلبات",
    listScroll2: "مركز الشحن",
    listScroll3: "اتبع المتجر",
    listScroll4: "أخبار خدمة العملاء",
    listScroll5: "رسالة",
    listScroll6: "المفضلة",
    listScroll7: "العنوان",
    listScroll8: "قسيمة البطاقة",
    listScroll9: "تسجيل الدخول",
    recommendCard1: "التوصية اليومية",
    recommendCard2: "شراء ذو قيمة فائقة",
    recommendCard3: "مميزة",
    recommendCard4: "ارتفاع سعر منخفض",
}
//  新增首页语言
homeLanguage = Object.assign({...homeLanguage, ...newHome6month7})

let newOrderDetail6month7 = {
    commentContent: "النظام الافتراضي هو الثناء! ",
    discussTags1: "الكل",
    discussTags2: "نجمة واحدة",
    discussTags3: "نجمتان",
    discussTags4: "3 نجوم",
    discussTags5: "4 نجوم",
    discussTags6: "5 نجوم",
    discussTags7: "هناك وسائط", // ملف الوسائط
    cheaper: "أرخص",
    SalesVolume: "حجم المبيعات",
    pieces: "القطع",
    leaveFor: "GO",
    resumption: "قريبًا",
    hint1: "إجمالي المبيعات",
    hint2: "إجمالي الطلب",
    hint3: "إجمالي المنتج",

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({...orderDetailLangage, ...newOrderDetail6month7})

// 新增评论详情语言
let newComment6month7 = {
    commentContent: "النظام الافتراضي هو الثناء! ",
    tabsTitle1: "الكل",
    tabsTitle2: "نجمة واحدة",
    tabsTitle3: "نجمتان",
    tabsTitle4: "3 نجوم",
    tabsTitle5: "4 نجوم",
    tabsTitle6: "5 نجوم",
    tabsTitle7: "لديك وسائط",
}
commentOnLanguage = Object.assign({...commentOnLanguage, ...newComment6month7})
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7: 'لعرض المزيد ، الرجاء تسجيل الدخول أولا'
}
messageLanguage = Object.assign({...newMessage6month7, ...messageLanguage})
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
