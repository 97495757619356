let hostConfig = document.domain.split('.').slice(-2).join('.')
const {baseUrl, url, downloadAndroidAppUrl, downloadIosAppUrl, storeHost} = getHostConfig()

// 语言转换
const lang = {
    null: 'english',
    English: 'english',
    日本語: 'japanese',
    Português: 'portuguese',
    繁體中文: 'chineseTraditional',
    简体中文: 'chinese',
    Français:'french',
    Deutsch:'german',
    Türk:'turkish',
    Español:'spanish',
    عربي : 'arabic',
    한국인: 'korean',
    Suomi: 'finland',
    Русский:'russian',
    italiano: 'italian',
    แบบไทย: 'thai',
    Nederlands: 'dutch',
}

/*******************************************************************/
// 店铺注册页
const shopRegisterWebUrl = '#/join-index?roteType=1'

// 店铺注册页
const shopLoginWebUrl = '#/login/loginIndex'

// 获取域名请求配置
function getHostConfig() {

    try {
        return require(`@/components/component/js/hostConfig/${hostConfig}`).hostConfigObj
    } catch {
        return require(`@/components/component/js/hostConfig/default`).hostConfigObj
    }
}

export default {
    url,
    downloadAndroidAppUrl,
    downloadIosAppUrl,
    baseUrl,
    lang,
    shopRegisterWebUrl,
    storeHost,
    shopLoginWebUrl
}