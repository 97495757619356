// 葡萄牙语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Atualização de versão, por favor confirme? ',// Atualização de versão, por favor confirme?
    loadingTitle: 'Baixando...', // Baixando...
    systemUpgrade: 'atualização do sistema', // atualização do sistema
    upgradeImmediately: 'Confirmar', // Confirmar
}

// 登录
let loginLanguage = {
    navTitle: 'login', // login
    title: 'Login',
    continue: 'Entre para continuar',
    forget: 'Esqueceu sua senha?', // atendimento ao cliente
    btnTitle: 'Login', //
    placeholderAccount: 'Por favor, digite o número de telefone',
    placeholderPassword: 'Digite sua senha', //
    leaveFor: 'Ir para registrar',
    haveTitle: 'Sem conta? ',
    errorTips: 'Erro de conta ou senha',
    loginShopTitle:'Entrada de login do comerciante'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'esqueci a senha', // esqueci a senha
    oldPass: 'Please enter your mobile phone number', // digite o número do celular
    verificationCode: 'Código de verificação SMS',
    newPass: 'Por favor, digite uma nova senha',
    Obtain: 'Enviar', // Obter
    btnTitle: 'Confirmar',
    notSaved: 'O número de telefone está incorreto',
}

// 注册
let registerLanguage = {
    navTitle: 'registrar', // registrar
    title: 'Inscreva-se',
    continue: 'Cadastre-se como membro',
    forget: 'Esqueceu sua senha? ',
    btnTitle: 'registrar', // registrar
    placeholderAccount: 'Por favor, digite o número de telefone',
    placeholderVerification: 'Código de convite',
    placeholderPassword: 'Digite sua senha', //
    Obtain: 'Enviar', //
    haveTitle: 'Já tem uma conta?',
    leaveFor: 'Ir para login',
    notSaved: 'O número de telefone está incorreto',
}

// 首页
let homeLanguage = {
    navTitle: 'Recomendação popular', // Home
    placeholder: 'Digite o nome do produto para pesquisar', // Por favor, insira o conteúdo
    tabsAll: "All", // Recomendado
    headerBgTitle: '0 fundos liquidados em loja transfronteiriça, vá para registrar',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Carregando', // Carregando
    finishedText: "nenhum", // nenhum
    loadingText: 'carregando', // carregando
}

// 产品列表
let orderPageLangage = {
    navTitle: "Lista de produtos", // Lista de pedidos
    placeholder: "Digite o nome do produto para pesquisar", //Critérios de pesquisa
    goodsTitle: 'bens', // mercadorias
    shopTitle: 'shop', // point shop
    goTitle: 'GO',//Ir para
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Detalhes do produto", // Lista de pedidos
    returnPolicy: 'Política de Devolução',//Política de Devolução
    slectTitle: 'select',//política de retorno
    slectContent: 'Selecione a especificação do produto',//Política de devolução
    Shipping: 'endereço',//endereço de entrega
    ShippingContent: 'enviar para o destino',//endereço de entrega
    freight: 'Frete',
    freightContent: 'frete grátis', // frete grátis
    Product: 'Produto',//Produto
    commentsDiscuss: 'comentários',//comentários
    Details: 'detalhes', //detalhes
    Recommend: 'Recomendar',//Recomendar
    ReturnPolicyTitle: 'Política de Devolução',
    policyBtn: 'Confirmar',
    specQuantity: 'Quantidade',
    specBtnbuyNow: 'Adicionar ao carrinho',
    specBtnShoppingCar: 'Compre agora',
    attrDefault: 'padrão',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Detalhes da loja'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'mais comentários'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Configurações multilíngues',
    affirm: 'confirmar',
}
// 页脚
let footerLanguage = {
    home: 'CASA',
    order: 'CARRO',
    Workbench: 'PESSOAL',
}
// 导航设置
let popupLanguage={
    settingLanguage:'Configurações multilíngues',
    shopRegister:'Registro da loja',
    shopLogin:'login da loja',
    service:'atendimento ao cliente',
    downloadAndroidApp:'baixar aplicativo android',
    downloadIosApp:'baixar aplicativo ios',
}
 // 客服
let serviceLanguage = {
    navTitle: 'Customer Service',
    customerService: 'Serviço exclusivo ao cliente',
    helloTitle: 'Hello',
    welcomeTitle: ' Eu sou seu <, você pode entrar em contato comigo através das seguintes maneiras',
    iconInformation: 'Por favor, selecione o software de comunicação para consulta',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}



/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Recomendado',
    tabes2: 'loja',
    searchPlaceholder: "Por favor, digite para pesquisar",
    listScroll1: 'Pedidos',
    listScroll2: 'Central de recarga',
    listScroll3: 'Siga a loja',
    listScroll4: 'Notícias de atendimento ao cliente',
    listScroll5: 'Mensagem',
    listScroll6: 'Favoritos',
    listScroll7: 'endereço',
    listScroll8: 'Cupom de Cartão',
    listScroll9: 'Entrar',
    recommendCard1: 'Recomendação diária',
    recommendCard2: 'Compra de super valor',
    recommendCard3: 'Destaque',
    recommendCard4: 'Pico de preço baixo',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: 'O padrão do sistema é elogiar! ',
    discussTags1: 'todos',
    discussTags2: '1 estrela',
    discussTags3: '2 estrelas',
    discussTags4: '3 estrelas',
    discussTags5: '4 estrelas',
    discussTags6: '5 estrelas',
    discussTags7: 'Existe mídia', //arquivo de mídia
    cheaper: 'mais barato',
    SalesVolume: 'Volume de vendas',
    pieces: 'peças',
    leaveFor: 'GO',
    resumption: 'em breve',
    hint1: 'vendas totais',
    hint2: 'Pedido total',
    hint3: 'produto total',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'O padrão do sistema é elogiar! ',
    tabsTitle1: 'Todos',
    tabsTitle2: '1 estrela',
    tabsTitle3: '2 estrelas',
    tabsTitle4: '3 estrelas',
    tabsTitle5: '4 estrelas',
    tabsTitle6: '5 estrelas',
    tabsTitle7: 'Tem mídia',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'Para ver mais, faça o login primeiro'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
