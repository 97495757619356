import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
    path: "/",
    name: "home",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    redirect: "/login/loginIndex",
    children: [{
        path: "/login/loginIndex",
        name: "loginIndex",
        component: () => import("@/views/page/login-index.vue"),
      },
      {
        path: "/login/registerLogin",
        name: "registerLogin",
        component: () => import("@/views/detail/registerLogin-index.vue"),
      },
      {
        path: "/login/changePassword",
        name: "changePassword",
        component: () => import("@/views/detail/changePassword-index.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    redirect: "/home/home-index",
    children: [{
      path: "/home/home-index",
      name: "homeIndex",
      component: () => import("@/views/page/home/home-index.vue"),
    }, ],
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("@/views/Order.vue"),
    redirect: "/order/order-index",
    children: [
      // {
      //   path: "/order/shop-index",
      //   name: "shopIndex",
      //   component: () => import("@/views/page/shop-index.vue"),
      // },
      {
        path: "/order/order-index",
        name: "orderIndex",
        component: () => import("@/views/page/order-index.vue"),
      },
      {
        path: "/order/order-index/shop-detail",
        name: "shopList",
        component: () => import("@/views/detail/shopList-detail.vue"),
      },
      {
        path: "/order/order-index/order-detail",
        name: "orderDetail",
        component: () => import("@/views/detail/order-detail.vue"),
      },
      {
        path: "/order/order-index/order-detail/commentOn-detail",
        name: "discuss",
        component: () => import("@/views/detail/commentOn-detail.vue"),
      },
    ],
  },
  {
    path: "/workbench",
    name: "Workbench",
    component: () => import("@/views/Workbench.vue"),
    redirect: "/order/order-index",
    children: [{
      path: "/workbench/workbench-index",
      name: "workbenchIndex",
      component: () => import("@/views/page/workbench-index.vue"),
    }, ],
  },
  {
    path: "/serviceIndex",
    name: "ServiceIndex",
    component: () => import("@/views/page/service-index.vue"),
  },
  {
    path: "/language/language-setting",
    name: "languageSetting",
    component: () => import("@/views/detail/language-shift.vue"),
  },

];

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router