import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import 'lib-flexible/flexible'
import '../src/utils/vant'
import './utils/appback.js'
import './utils/version'

// 引入timeline
import moreDialog from './utils/modularization';
Vue.use(moreDialog);
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)
require('vue-video-player/src/custom-theme.css')


import global_msg from './components/component/js/global'
import VueClipboard from 'vue-clipboard2' //引入插件
Vue.use(VueClipboard) //安装插件
Vue.prototype.$globalCommon = global_msg;
Vue.prototype.$JumpShops = global_msg.$JumpShops;
Vue.config.productionTip = false
let time = 0
export default timeDate => {
    setTimeout(({
        time: 1
    }), 6000)
    return time
}

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')