// 英语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Version upgrade, please confirm? ',// Version upgrade, please confirm?
    loadingTitle: 'Downloading...', // Downloading...
    systemUpgrade: 'system upgrade', // system upgrade
    upgradeImmediately: 'Confirm', // Confirm
}

// 登录
let loginLanguage = {
    navTitle: 'login', // login
    title: 'Login',
    continue: 'Login to continue',
    forget: 'Forgot your password?', // customer service
    btnTitle: 'Login', //
    placeholderAccount: 'Please enter the phone number',
    placeholderPassword: 'Please enter your password', //
    leaveFor: 'Go to register',
    haveTitle: 'No account? ',
    errorTips: 'Account or password error',
    loginShopTitle: 'Merchant login entry'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'forgot password', // forgot password
    oldPass: 'Please enter your mobile phone number', // enter the mobile phone number
    verificationCode: 'SMS verification code',
    newPass: 'Please enter a new password',
    Obtain: 'Send',// Get
    btnTitle: 'Confirm',
    notSaved: 'The phone number is incorrect',
}

// 注册
let registerLanguage = {
    navTitle: 'register', // register
    title: 'Sign up',
    continue: 'Register as a member',
    forget: 'Forgot your password? ',
    btnTitle: 'register', // register
    placeholderAccount: 'Please enter the phone number',
    placeholderVerification: 'Invitation Code',
    placeholderPassword: 'Please enter your password', //
    Obtain: 'Send', //
    haveTitle: 'Already have an account?',
    leaveFor: 'Go to login',
    notSaved: 'The phone number is incorrect',
}

// 首页
let homeLanguage = {
    // 旧版
    navTitle: 'Popular recommendation', // Home
    placeholder: 'Enter the product name to search',// Please enter the content
    tabsAll: "All", // Recommended
    headerBgTitle: '0 funds settled in cross-border shop, go to register',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Loading', // Loading
    finishedText: "none", //none
    loadingText: 'loading', // loading
}

// 产品列表
let orderPageLangage = {
    navTitle: "Product List", // Order List
    placeholder: "Enter product name to search", //Search criteria
    goodsTitle: 'goods', // goods
    shopTitle: 'shop',// point shop
    goTitle: 'GO',//Go to
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Product Details", // Order List
    returnPolicy: 'Return Policy',//Return Policy
    slectTitle: 'select',//return policy
    slectContent: 'Select product specification',//Return policy
    Shipping: 'address',//shipping address
    ShippingContent: 'ship to destination',//shipping address
    freight: 'Freight',
    freightContent: 'free shipping', // free shipping
    Product: 'Product',//Product
    commentsDiscuss: 'comments',//comments
    Details: 'details',//details
    Recommend: 'Recommend',//Recommend
    ReturnPolicyTitle: 'Return Policy',
    policyBtn: 'Confirm',
    specQuantity: 'Quantity',
    specBtnbuyNow: 'Add to cart',
    specBtnShoppingCar: 'Buy Now',
    attrDefault: 'default',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Store details'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'more comments'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Multilingual settings',
    affirm: 'confirm',
}
// 页脚
let footerLanguage = {
    home: 'HOME',
    order: 'CAR',
    Workbench: 'PERSONAL',
}
// 导航设置
let popupLanguage = {
    settingLanguage: 'Multilingual settings',
    shopRegister: 'Store registration',
    shopLogin: 'store login',
    service: 'customer service',
    downloadAndroidApp: 'download android app',
    downloadIosApp: 'download ios app',
}
// 客服
let serviceLanguage = {
    navTitle: 'customer service',
    customerService: 'Exclusive customer service',
    helloTitle: 'Hello',
    welcomeTitle: 'I am your <, you can contact me through the following ways',
    IconInformation: 'Please select communication software for consultation',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}



/**    
 * 2023年6月7日21:02:19新版商城
  */

let newHome6month7 = {
    tabes1: 'Recommended',
    tabes2: 'shop',
    searchPlaceholder: "Please enter to search",
    listScroll1: 'Orders',
    listScroll2: 'Recharge Center',
    listScroll3: 'Follow the store',
    listScroll4: 'Customer service news',
    listScroll5: 'Message',
    listScroll6: 'Favorites',
    listScroll7: 'address',
    listScroll8: 'Card Coupon',
    listScroll9: 'Sign in',
    recommendCard1: 'Daily recommendation',
    recommendCard2: 'Super value purchase',
    recommendCard3: 'Featured',
    recommendCard4: 'Low price spike',
}
//  新增首页语言
homeLanguage = Object.assign({ ...homeLanguage, ...newHome6month7 })

let newOrderDetail6month7 = {
    commentContent: 'The system defaults to praise! ',
    discussTags1: 'all',
    discussTags2: '1 star',
    discussTags3: '2 stars',
    discussTags4: '3 stars',
    discussTags5: '4 stars',
    discussTags6: '5 stars',
    discussTags7: 'There is media', //media file
    cheaper: 'cheaper',
    SalesVolume: 'Sales Volume',
    pieces: 'pieces',
    leaveFor: 'GO',
    resumption: 'coming soon',
    hint1: 'total sales',
    hint2: 'Total order',
    hint3: 'total product',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({ ...orderDetailLangage, ...newOrderDetail6month7 })

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'The system defaults to praise! ',
    tabsTitle1: 'All',
    tabsTitle2: '1 star',
    tabsTitle3: '2 stars',
    tabsTitle4: '3 Star',
    tabsTitle5: '4 stars',
    tabsTitle6: '5 Stars',
    tabsTitle7: 'Have media',
}
commentOnLanguage = Object.assign({ ...commentOnLanguage, ...newComment6month7 })
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7:'To view more, please log in first'
}
messageLanguage = Object.assign({ ...newMessage6month7, ...messageLanguage })
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
