// 日语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'バージョンアップ,確認しますか? ',// バージョン アップグレード,確認してください?
    loadingTitle: 'ダウンロード中...', // ダウンロード中...
    systemUpgrade: 'システムのアップグレード', // システムのアップグレード
    upgradeImmediately: '確認', // 確認


}

// 登录
let loginLanguage = {
    navTitle: 'login', // ログイン
    title: 'ログイン',
    continue: 'ログインして続ける',
    forget: 'パスワードをお忘れですか?', // カスタマーサービス
    btnTitle: 'ログイン', //
    placeholderAccount: '電話番号を入力してください',
    placeholderPassword: 'パスワードを入力してください', //
    leaveFor: '登録する',
    haveTitle: 'アカウントがありませんか? ',
    errorTips: 'アカウントまたはパスワードのエラー',
    loginShopTitle: 'マーチャント ログイン エントリ'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'パスワードを忘れた', // パスワードを忘れた
    oldPass: '携帯電話番号を入力してください', // 携帯電話番号を入力してください
    verificationCode: 'SMS 検証コード',
    newPass: '新しいパスワードを入力してください',
    Obtain: '送信',// 取得
    btnTitle: '確認',
    notSaved: '電話番号が正しくありません',
}

// 注册
let registerLanguage = {
    navTitle: 'register', // 登録
    title: 'サインアップ',
    continue: '会員登録',
    forget: 'パスワードをお忘れですか? ',
    btnTitle: 'register', // 登録
    placeholderAccount: '電話番号を入力してください',
    placeholderVerification: '招待コード',
    placeholderPassword: 'パスワードを入力してください', //
    Obtain: '送信', //
    haveTitle: 'すでにアカウントをお持ちですか?',
    leaveFor: 'ログインに移動',
    notSaved: '電話番号が正しくありません',
}

// 首页
let homeLanguage = {
    navTitle: '人気のおすすめ', // ホーム
    placeholder: '商品名を入力して検索',// 内容を入力してください
    tabsAll: "全て", // 推奨
    headerBgTitle: '国境を越えた店で決済された0資金, 登録する',
}

// 提示文本
let messageLanguage = {
    loadingTitle: '読み込み中', // 読み込み中
    finishedText: "なし", //なし
    loadingText: '読み込み中', // 読み込み中
}

// 产品列表
let orderPageLangage = {
    navTitle: "商品一覧", // 注文一覧
    placeholder: "検索する製品名を入力してください", //検索基準
    goodsTitle: '製品', // 商品
    shopTitle: '店',// ポイントショップ
    goTitle: 'GO',//移動
};

//  产品详情
let orderDetailLangage = {
    navTitle: "商品詳細", // 注文リスト
    returnPolicy: '返品ポリシー',//返品ポリシー
    slectTitle: '選択する',//返品ポリシー
    slectContent: '製品仕様の選択',//返品ポリシー
    Shipping: '住所',//配送先住所
    ShippingContent: '目的地に発送する',//配送先住所
    freight: '貨物',
    freightContent: '送料無料', // 送料無料
    Product: '製品',//製品
    commentsDiscuss: 'コメント',//comments
    Details: '詳細',//詳細
    Recommend: 'おすすめ',//おすすめ
    ReturnPolicyTitle: '返品ポリシー',
    policyBtn: '確認',
    specQuantity: '数量',
    specBtnbuyNow: 'カートに入れる',
    specBtnShoppingCar: '今すぐ購入',
    attrDefault: 'デフォルト',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: '店舗詳細'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'その他のコメント'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '多言語設定',
    affirm: '確認する',
}
// 页脚
let footerLanguage = {
    home: '家',
    order: '車',
    Workbench: '個人的',
}
// 导航设置
let popupLanguage = {
    settingLanguage: '多言語設定',
    shopRegister: '店舗登録',
    shopLogin: '店舗ログイン',
    service: 'カスタマーサービス',
    downloadAndroidApp: 'アンドロイドアプリをダウンロード',
    downloadIosApp: 'iOSアプリをダウンロード',
}
// 客服
let serviceLanguage = {
    navTitle: 'カスタマーサービス',
    customerService: '専属カスタマーサービス',
    helloTitle: 'こんにちは',
    welcomeTitle: '私はあなたの<で,あなたは以下の方法で私に連絡することができます',
    MiconInformation: 'コミュニケーションソフトウェアを選択してお問い合わせください',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}


/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: '推奨',
    tabes2: 'ショップ',
    searchPlaceholder: "検索するには入力してください",
    listScroll1: '注文',
    listScroll2: 'リチャージ センター',
    listScroll3: 'ストアをフォローする',
    listScroll4: 'カスタマーサービスニュース',
    listScroll5: 'メッセージ',
    listScroll6: 'お気に入り',
    listScroll7: 'アドレス',
    listScroll8: 'カードクーポン',
    listScroll9: 'サインイン',
    recommendCard1: '今日のおすすめ',
    recommendCard2: '超お得な買い物',
    recommendCard3: '注目',
    recommendCard4: '低価格スパイク',
}
//  新增首页语言
homeLanguage = Object.assign({...homeLanguage, ...newHome6month7})

let newOrderDetail6month7 = {
    commentContent: 'システムのデフォルトは賞賛です! ',
    discussTags1: 'すべて',
    discussTags2: '1 つ星',
    discussTags3: '2つ星',
    discussTags4: '3 つ星',
    discussTags5: '4つ星',
    discussTags6: '5 つ星',
    discussTags7: 'メディアがあります', //メディア ファイル
    cheaper: '安い',
    SalesVolume: '販売数量',
    pieces: '個',
    leaveFor: 'GO',
    resumption: '近日公開',
    hint1: '総売上高',
    hint2: '合計注文数',
    hint3: '総積',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({...orderDetailLangage, ...newOrderDetail6month7})

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'システムのデフォルトは賞賛です! ',
    tabsTitle1: 'すべて',
    tabsTitle2: '1 つ星',
    tabsTitle3: '2 つ星',
    tabsTitle4: '3 つ星',
    tabsTitle5: '4 つ星',
    tabsTitle6: '5 つ星',
    tabsTitle7: 'メディアあり',
}
commentOnLanguage = Object.assign({...commentOnLanguage, ...newComment6month7})
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7: 'さらに見るには、まずログインしてください'
}
messageLanguage = Object.assign({...newMessage6month7, ...messageLanguage})
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
