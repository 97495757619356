// 泰语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'อัพเกรดเวอร์ชั่น กรุณายืนยัน? ',// อัปเกรดเวอร์ชัน โปรดยืนยัน?
    loadingTitle: 'กำลังดาวน์โหลด...', // กำลังดาวน์โหลด...
    systemUpgrade: 'อัพเกรดระบบ', // อัพเกรดระบบ
    upgradeImmediately: 'ยืนยัน' // ยืนยัน
}

// 登录
let loginLanguage = {
    navTitle: 'เข้าสู่ระบบ', // เข้าสู่ระบบ
    title: 'เข้าสู่ระบบ',
    continue: 'เข้าสู่ระบบเพื่อดำเนินการต่อ',
    forget: 'ลืมรหัสผ่าน', // ฝ่ายบริการลูกค้า
    btnTitle: 'เข้าสู่ระบบ', //
    placeholderAccount: 'กรุณาใส่หมายเลขโทรศัพท์',
    placeholderPassword: 'กรุณาใส่รหัสผ่าน', //
    leaveFor: 'ไปลงทะเบียน',
    haveTitle: 'ไม่มีบัญชี? ',
    errorTips: 'บัญชีหรือรหัสผ่านผิดพลาด',
    loginShopTitle: 'รายการเข้าสู่ระบบร้านค้า'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'ลืมรหัสผ่าน', // ลืมรหัสผ่าน
    oldPass: 'กรุณาใส่หมายเลขโทรศัพท์มือถือของคุณ', // ป้อนหมายเลขโทรศัพท์มือถือ
    verificationCode: 'รหัสยืนยันทาง SMS',
    newPass: 'โปรดป้อนรหัสผ่านใหม่',
    Obtain: 'ส่ง', // รับ
    btnTitle: 'ยืนยัน',
    notSaved: 'หมายเลขโทรศัพท์ไม่ถูกต้อง'
}

// 注册
let registerLanguage = {
    navTitle: 'ลงทะเบียน', // ลงทะเบียน
    title: 'ลงทะเบียน',
    continue: 'สมัครสมาชิก',
    forget: 'ลืมรหัสผ่าน? ',
    btnTitle: 'ลงทะเบียน', // ลงทะเบียน
    placeholderAccount: 'กรุณาใส่หมายเลขโทรศัพท์',
    placeholderVerification: 'รหัสเชิญ',
    placeholderPassword: 'กรุณาใส่รหัสผ่าน', //
    Obtain: 'ส่ง', //
    haveTitle: 'มีบัญชีอยู่แล้ว',
    leaveFor: 'ไปที่การเข้าสู่ระบบ',
    notSaved: 'หมายเลขโทรศัพท์ไม่ถูกต้อง'
}

// 首页
let homeLanguage = {
    navTitle: 'คำแนะนำยอดนิยม', // หน้าแรก
    placeholder: 'ป้อนชื่อผลิตภัณฑ์ที่ต้องการค้นหา',// โปรดป้อนเนื้อหา
    tabsAll: "ทั้งหมด", // แนะนำ
    headerBgTitle: '0 เงินชำระในร้านค้าข้ามพรมแดน ไปที่การลงทะเบียน'
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'กำลังโหลด', // กำลังโหลด
    finishedText: "ไม่มี", //ไม่มี
    loadingText: 'กำลังโหลด', // กำลังโหลด
}

// 产品列表
let orderPageLangage = {
    navTitle: "รายการสินค้า", // รายการสั่งซื้อ
    placeholder: "ป้อนชื่อผลิตภัณฑ์เพื่อค้นหา", //เกณฑ์การค้นหา
    goodsTitle: 'สินค้า', // สินค้า
    shopTitle: 'shop',//ชี้ร้าน
    goTitle: 'GO',//ไปที่
};

//  产品详情
let orderDetailLangage = {
    navTitle: "รายละเอียดสินค้า", // รายการสั่งซื้อ
    returnPolicy: 'นโยบายการคืนสินค้า',//นโยบายการคืนสินค้า
    slectTitle: 'เลือก',//นโยบายการคืนสินค้า
    slectContent: 'เลือกข้อมูลจำเพาะของผลิตภัณฑ์',//นโยบายการคืนสินค้า
    Shipping: 'ที่อยู่',//ที่อยู่สำหรับจัดส่ง
    ShippingContent: 'จัดส่งไปยังปลายทาง',//ที่อยู่สำหรับจัดส่ง
    freight: 'ค่าขนส่ง',
    freightContent: 'จัดส่งฟรี', // จัดส่งฟรี
    Product: 'สินค้า',//สินค้า
    commentsDiscuss: 'ความคิดเห็น',//ความคิดเห็น
    Details: 'รายละเอียด',//รายละเอียด
    Recommend: 'แนะนำ',//แนะนำ
    ReturnPolicyTitle: 'นโยบายการคืน',
    policyBtn: 'ยืนยัน',
    specQuantity: 'ปริมาณ',
    specBtnbuyNow: 'หยิบใส่ตะกร้า',
    specBtnShoppingCar: 'ซื้อเลย',
    attrDefault: 'ค่าเริ่มต้น',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'รายละเอียดร้านค้า'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'ความคิดเห็นเพิ่มเติม'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'การตั้งค่าหลายภาษา',
    affirm: 'ยืนยัน',
}
// 页脚
let footerLanguage = {
    home: 'บ้าน',
    order: 'CAR',
    Workbench: 'ส่วนตัว',
}
// 导航设置
let popupLanguage = {
    settingLanguage: 'การตั้งค่าหลายภาษา',
    shopRegister: 'การลงทะเบียนร้านค้า',
    shopLogin: 'เข้าสู่ระบบร้านค้า',
    service: 'บริการลูกค้า',
    downloadAndroidApp: 'ดาวน์โหลดแอป android',
    downloadIosApp: 'ดาวน์โหลดแอป ios',
}
// 客服
let serviceLanguage = {
    navTitle: 'บริการลูกค้า',
    customerService: 'บริการลูกค้าพิเศษ',
    helloTitle: 'สวัสดี',
    welcomeTitle: 'ฉันคือ < ของคุณ คุณสามารถติดต่อฉันได้ด้วยวิธีต่อไปนี้',
    IconInformation: 'โปรดเลือกซอฟต์แวร์การสื่อสารเพื่อขอคำปรึกษา',
    whatsApp: 'วอทส์แอพ',
    telegram: 'โทรเลข',
    line: 'บรรทัด',
    kakao: 'kakao'
}


/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'แนะนำ',
    tabes2: 'ร้านค้า',
    searchPlaceholder: "กรุณาป้อนเพื่อค้นหา",
    listScroll1: 'คำสั่งซื้อ',
    listScroll2: 'ศูนย์เติมเงิน',
    listScroll3: 'ติดตามร้านค้า',
    listScroll4: 'ข่าวบริการลูกค้า',
    listScroll5: 'ข้อความ',
    listScroll6: 'รายการโปรด',
    listScroll7: 'ที่อยู่',
    listScroll8: 'คูปองบัตร',
    listScroll9: 'ลงชื่อเข้าใช้',
    recommendCard1: 'คำแนะนำรายวัน',
    recommendCard2: 'ซื้อสุดคุ้ม',
    recommendCard3: 'แนะนำ',
    recommendCard4: 'ราคาพุ่งต่ำ',
}
//  新增首页语言
homeLanguage = Object.assign({...homeLanguage, ...newHome6month7})

let newOrderDetail6month7 = {
    commentContent: 'ระบบเริ่มต้นสรรเสริญ! ',
    discussTags1: 'ทั้งหมด',
    discussTags2: '1 ดาว',
    discussTags3: '2 stars',
    discussTags4: '3 stars',
    discussTags5: '4 stars',
    discussTags6: '5 stars',
    discussTags7: 'มีสื่อ', //ไฟล์มีเดีย
    cheaper: 'ถูกกว่า',
    SalesVolume: 'ปริมาณการขาย',
    pieces: 'ชิ้น',
    leaveFor: 'ไป',
    resumption: 'เร็ว ๆ นี้',
    hint1: 'ยอดขายรวม',
    hint2: 'คำสั่งซื้อทั้งหมด',
    hint3: 'ผลิตภัณฑ์ทั้งหมด',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({...orderDetailLangage, ...newOrderDetail6month7})

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'ระบบเริ่มต้นสรรเสริญ! ',
    tabsTitle1: 'ทั้งหมด',
    tabsTitle2: '1 ดาว',
    tabsTitle3: '2 ดาว',
    tabsTitle4: '3 ดาว',
    tabsTitle5: '4 ดาว',
    tabsTitle6: '5 ดาว',
    tabsTitle7: 'มีสื่อ',
}
commentOnLanguage = Object.assign({...commentOnLanguage, ...newComment6month7})
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7: 'หากต้องการดูเพิ่มเติม กรุณาเข้าสู่ระบบก่อน'
}
messageLanguage = Object.assign({...newMessage6month7, ...messageLanguage})
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
