// 俄语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    // '简体中文',  // 简体中文
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Обновление версии, пожалуйста, подтвердите? ', // Обновление версии, пожалуйста, подтвердите?
    loadingTitle: 'Загрузка...', // Загрузка...
    systemUpgrade: 'обновление системы', // обновление системы
    upgradeImmediately: 'Подтвердить', // Подтвердить
}

// 登录
let loginLanguage = {
    navTitle: 'логин', // логин
    title: 'Войти',
    continue: 'Войдите, чтобы продолжить',
    forget: "Забыли пароль?", // служба поддержки клиентов
    btnTitle: 'Вход', //
    placeholderAccount: 'Пожалуйста, введите номер телефона',
    placeholderPassword: 'Пожалуйста, введите ваш пароль', //
    leaveFor: 'Перейти к регистрации',
    haveTitle: 'Нет учетной записи? ',
    errorTips: 'Ошибка учетной записи или пароля',
    loginShopTitle: 'Вход для входа в систему продавца'

}

// 忘记密码
let forgetPassword = {
    navTitle: 'забыли пароль', // забыли пароль
    oldPass: 'Пожалуйста, введите номер вашего мобильного телефона', // введите номер мобильного телефона
    verificationCode: 'SMS код подтверждения',
    newPass: 'Пожалуйста, введите новый пароль',
    Obtain: 'Отправить', // Получить
    btnTitle: 'Подтвердить',
    notSaved: 'Неверный номер телефона',
}

// 注册
let registerLanguage = {
    navTitle: 'зарегистрироваться', // зарегистрироваться
    title: 'Зарегистрироваться',
    continue: 'Зарегистрируйтесь как участник',
    forget: 'Забыли пароль? ',
    btnTitle: 'зарегистрироваться', // зарегистрироваться
    placeholderAccount: 'Пожалуйста, введите номер телефона',
    placeholderVerification: 'Код приглашения',
    placeholderPassword: 'Пожалуйста, введите ваш пароль', //
    Obtain: 'Отправить', //
    haveTitle: 'Уже есть аккаунт?',
    leaveFor: 'Перейти к входу',
    notSaved: 'Неверный номер телефона',
}

// 首页
let homeLanguage = {
    navTitle: 'Популярная рекомендация', // Главная
    placeholder: 'Введите название продукта для поиска', // Пожалуйста, введите содержимое
    tabsAll: "Все", // Рекомендуется
    headerBgTitle: '0 средств зачислено в международный магазин, пройдите регистрацию',
}

// 提示文本
let messageLanguage = {
    loadingTitle: 'Загрузка', // Загрузка
    finishedText: "нет", //нет
    loadingText: 'загрузка', // загрузка
}

// 产品列表
let orderPageLangage = {
    navTitle: "Список товаров", // Список заказов
    placeholder: "Введите название продукта для поиска", //Критерии поиска
    goodsTitle: 'товары', // товары
    shopTitle: 'shop', // точечный магазин
    goTitle: 'GO',//Перейти к
};

//  产品详情
let orderDetailLangage = {
    navTitle: "Сведения о продукте", // Список заказов
    returnPolicy: 'Политика возврата',//Политика возврата
    slectTitle: 'выбрать',// политика возврата
    slectContent: 'Выберите спецификацию продукта',//Политика возврата
    Shipping: 'адрес',//адрес доставки
    ShippingContent: 'отправить в пункт назначения', // адрес доставки
    freight: 'Груз',
    freightContent: 'бесплатная доставка', // бесплатная доставка
    Product: 'Продукт',//Продукт
    commentsDiscuss: 'комментарии',//комментарии
    Details: 'детали',//детали
    Recommend: 'Рекомендовать',//Рекомендовать
    ReturnPolicyTitle: 'Политика возврата',
    policyBtn: 'Подтвердить',
    specQuantity: 'Количество',
    specBtnbuyNow: 'Добавить в корзину',
    specBtnShoppingCar: 'Купить сейчас',
    attrDefault: 'по умолчанию',
};

// 店铺详情页
let shopListLanguage = {
    navTitle: 'Детали магазина'
}

// 评论详情
let commentOnLanguage = {
    navTitle: 'больше комментариев'
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Многоязычные настройки',
    affirm: 'подтверждать',
}
// 页脚
let footerLanguage = {
    home: 'HOME',
    order: 'CAR',
    Workbench: 'PERSONAL',
}
// 导航设置
let popupLanguage = {
    settingLanguage: 'Многоязычные настройки',
    shopRegister: 'Регистрация магазина',
    shopLogin: 'вход в магазин',
    service: 'обслуживание клиентов',
    downloadAndroidApp: 'загрузить приложение для Android',
    downloadIosApp: 'загрузить приложение ios',
}
// 客服
let serviceLanguage = {
    navTitle: 'обслуживание клиентов',
    customerService: 'Эксклюзивное обслуживание клиентов',
    helloTitle: 'Привет',
    welcomeTitle: 'Я ваш <, вы можете связаться со мной следующими способами',
    IconInformation: 'Пожалуйста, выберите коммуникационное программное обеспечение для консультации',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao'
}


/**
 * 2023年6月7日21:02:19新版商城
 */

let newHome6month7 = {
    tabes1: 'Рекомендуется',
    tabes2: 'магазин',
    searchPlaceholder: "Пожалуйста, введите для поиска",
    listScroll1: 'Заказы',
    listScroll2: 'Центр перезарядки',
    listScroll3: 'Следить за магазином',
    listScroll4: 'Новости обслуживания клиентов',
    listScroll5: 'Сообщение',
    listScroll6: 'Избранное',
    listScroll7: 'адрес',
    listScroll8: 'Купон карты',
    listScroll9: 'Войти',
    recommendCard1: 'Ежедневная рекомендация',
    recommendCard2: 'Выгодная покупка',
    recommendCard3: 'Избранное',
    recommendCard4: 'Низкий скачок цен',
}
//  新增首页语言
homeLanguage = Object.assign({...homeLanguage, ...newHome6month7})

let newOrderDetail6month7 = {
    commentContent: 'Система по умолчанию хвалит! ',
    discussTags1: 'все',
    discussTags2: '1 звезда',
    discussTags3: '2 звезды',
    discussTags4: '3 звезды',
    discussTags5: '4 звезды',
    discussTags6: '5 звезд',
    discussTags7: 'Есть медиа', //медиафайл
    cheaper: 'дешевле',
    SalesVolume: 'Объем продаж',
    pieces: 'кусочки',
    leaveFor: 'GO',
    resumption: 'скоро',
    hint1: 'общий объем продаж',
    hint2: "Общий заказ",
    hint3: 'общий продукт',

}
// 订单详情新增语言包
orderDetailLangage = Object.assign({...orderDetailLangage, ...newOrderDetail6month7})

// 新增评论详情语言
let newComment6month7 = {
    commentContent: 'Система по умолчанию хвалит! ',
    tabsTitle1: 'Все',
    tabsTitle2: '1 звезда',
    tabsTitle3: '2 звезды',
    tabsTitle4: '3 звезды',
    tabsTitle5: '4 звезды',
    tabsTitle6: '5 звезд',
    tabsTitle7: 'Есть медиа',
}
commentOnLanguage = Object.assign({...commentOnLanguage, ...newComment6month7})
// 新增提示语言包
let newMessage6month7 = {
    finishedText6month7: 'Чтобы увидеть больше, сначала войдите в систему'
}
messageLanguage = Object.assign({...newMessage6month7, ...messageLanguage})
// messageLanguage
// finishedText
export default ({
    serviceLanguage,
    popupLanguage,
    shopListLanguage,
    commentOnLanguage,
    configLanguage,
    loginLanguage,
    registerLanguage,
    forgetPassword,
    homeLanguage,
    messageLanguage,
    languageVersion,
    orderPageLangage,
    footerLanguage,
    orderDetailLangage,
    languageShiftLanguage
})
